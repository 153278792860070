<template>
<div class="tables">
    <div class="table-container">
        <div class="table-title">{{lang.openOrders}}</div>
        <table>
            <tr class="table-header">
                <td class="resp-1">{{lang.tableHeaders.date}}</td>
                <td>{{lang.tableHeaders.type}}</td>
                <td>{{lang.tableHeaders.price}}</td>
                <td>{{lang.tableHeaders.amount}}</td>
                <td class="resp-2">{{lang.tableHeaders.total}}</td>
                <td class="resp-1"> {{lang.tableHeaders.filled}}</td>
                <td> {{lang.tableHeaders.action}} </td>
            </tr>
            <tr class="table-row" v-for="(order,index) in orders.filter(order=>order.orderStatus==='IS_OPEN' )" :key='index'>
                <td class="resp-1">{{$G2J(order.createdAtDateTime)}}</td>
                <td :class="{red:order.orderType.includes('SELL'),green:order.orderType.includes('BUY')}">
                    {{order.orderType.includes('BUY')?lang.BUY:lang.SELL}}&nbsp;/&nbsp;{{tradeType(order.orderType)}}</td>
                <td>{{$toLocal(order.unitPrice,0)}}</td>
                <td>{{order.amount}}</td>
                <td class="resp-2">{{$toLocal(order.wholePrice,0)}}</td>
                <td class="resp-1">{{order.executedPercent}}</td>
                <td @click="cancelOrderModal(order)" class="cancel-btn">cancel</td>
            </tr>
        </table>
    </div>
    <div class="table-container">
        <div class="table-title">{{lang.recentOrders}}</div>
        <table>
            <tr class="table-header">
                <td class="resp-1">{{lang.tableHeaders.date}}</td>
                <td>{{lang.tableHeaders.type}}</td>
                <td>{{lang.tableHeaders.price}}</td>
                <td>{{lang.tableHeaders.amount}}</td>
                <td class="resp-2">{{lang.tableHeaders.total}}</td>
            </tr>
            <tr class="table-row" v-for="(trade,index) in trades" :key="index">
                <td class="resp-1">{{$G2J(trade.createdAtDateTime)}}</td>
                <td :class="{red:trade.orderType.includes('SELL'),green:trade.orderType.includes('BUY')}">
                    {{trade.orderType.includes('BUY')?lang.BUY:lang.SELL}}&nbsp;/&nbsp;{{tradeType(trade.orderType)}}</td>
                <td>{{$toLocal(trade.unitPrice,0)}}</td>
                <td>{{trade.amount}}</td>
                <td class="resp-2">{{$toLocal(trade.wholePrice,0)}}</td>
               
            </tr>
        </table>
    </div>
    <CancelModal v-if="modalType=='CANCEL'" @modalToggle="value=>modalType=value" @confirm="removeorder()" />

</div>
</template>

<script>
import CancelModal from '../../Modals/CancelModal'

import {

    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'WalletSection',
    props: {},
    components: {
        CancelModal
    },
    data() {
        return {
            orderLoop: '',
            tradeLoop: '',
            trades: [],
            lang: this.$languageDict().dashboard.tables,
            //  ordersType:'ActiveOrders',
            orders: [],
            removeid: '',
            modalType: '',
            conditions: {
                'FINISHED_WITHOUT_TRANSACTION': 'موفق',
                'COMPLETED': 'موفق',
                'CANCELLED_BY_USER': 'لغو شده'
            }

        }
    },
    methods: {
        async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalType = ''
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getLastOrders()
                }
            }
        },
        cancelOrderModal(e) {
            this.loop.stop()
            this.modalType = 'CANCEL'
            this.removeid = e.id
        },
        tradeType(type) {
            return this.lang[type.split('_')[0]]
        },
        async getLastOrders() {
            // if (this.page <= this.lastPage) {
            this.page++
            let res = await this.$axios.get('/orders', {
                params: {
                    size: 3,
                    IS_OPEN:true,
                }
            })
            if (res.content)
                this.orders = res.content;
            this.loading = false
        },
        async getTrades() {
            this.loading = true
            // if (this.page <= this.lastPage) {
            let [res, ] = await this.$http.get(`/trades${'?size=3&type=EXCHANGE&type=P2P'}`)
            this.trades = [...res.content];
            // this.lastPage = res.totalPages
            // }
            this.loading = false
        }
        // },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.orderLoop = new Loop(this.getLastOrders, 5000, 'lastOrder')
            this.tradeLoop = new Loop(this.getTrades, 5000, 'getTrades')
            this.orderLoop.start()
            this.tradeLoop.start()
        } else {
            this.getLastOrders()
            this.getTrades()
        }

    },
    beforeDestroy() {
        this.orderLoop.stop()
        this.tradeLoop.stop()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.table-title {
    background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
    padding: 13px clamp(8px, 1vw, 16px);
    font-size: clamp(15px, 1.6vw, 18px);
    font-weight: bold;
    color: #FFFFFF;
}

.table-container {
    border-radius: 8px;
    overflow: hidden;
    flex-grow: 1;
    background: #1B2444;
}

td {
    padding: 9px clamp(8px, 1vw, 16px);
}

.table-row {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #FCFCFC;
}

.table-header {
    color: #BDBDBD;
    font-size: clamp(11px, 1.3vw, 13px);

}

table {
    width: 100%;
}

.tables {
  margin-top:8px;
    display: flex;
    row-gap: 8px;
    flex-direction: column;
}

.red {
    color: #E30037;
}

.green {
    color: #28F260;
}
@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
