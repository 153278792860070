<template>
<div class="navbar">
    <div class="links">
        <div class="site-icon" @click.prevent="$router.push('/')">
            <img class="site-logo" src='../../assets/icons/alpha.png' />
            <span class="site-title">{{lang.siteTitle}}</span>
        </div>
        <div class="link" v-for="(link,index) in links" :key="index" @click=" link=='Exchange' || link=='Trade'?$router.push('/Panel/'+link):$router.push('/'+link)">
            {{lang.links[link]}}
        </div>
    </div>
    <!-- <div class="lang-container " @click="handleLang()"> 
        <img :src="require('../../assets/icons/'+state.lang+'-flag.svg')" />
    </div> -->
    <div class="btns">
        <a href="/Login" @click.prevent="$router.push('/Login')">
            <button class="login-btn btn">
                <span class="gradiant-font">{{lang.login}}</span>
            </button>
        </a>
        <a href="/SignUp" @click.prevent="$router.push('/SignUp')">
            <button class="signup-btn btn">{{lang.signUp}}</button>
        </a>
    </div>

    <!-- <div v-if="state.token" class="profile-group">
        <img src="../../assets/icons/notifications.svg" @click="$router.push('/Panel/Notifications')" />
        <div class="lang-container " @click="handleLang()">
            <img :src="require('../../assets/icons/'+state.lang+'-flag.svg')" />
        </div>
        <div class="user-profile" @click="$router.push('/Panel/Dashboard')">
            <div class="username-container">
                <div class="user-brief-name">
                    {{state.userInfo.firstName[0]+state.userInfo.lastName[0]}}
                </div>
                <div class="user-fullname">
                    {{state.userInfo.firstName+' '+state.userInfo.lastName}}
                </div>

            </div>
            <inline-svg :src="require('../../assets/icons/arrowdown.svg')" class="arrow-icon" />

        </div>
    </div> -->
</div>
</template>

<script>
export default {
    name: "PublicNavbar",
    methods: {
        handleLang() {
            this.state.lang = (this.state.lang == 'en') ? 'fa' : 'en'
            localStorage.setItem('lang', this.state.lang)

        }
    },
    data() {
        return {
            lang:this.$languageDict().publicNavbar,
            links: [
                "Markets",
                "Trade",
                "Exchange",
                "Guide",
                "Blog",
                "FAQ",
                "Terms"
                // {
                //     label: "Trade",
                //     name: "Trade"
                // },
                // {
                //     label: "Exchange",
                //     name: "Exchange"
                // },
                // {
                //     label: "Guide",
                //     name: "Guide"
                // },
                // {
                //     label: "Blog",
                //     name: "Blog"
                // },
                // {
                //     label: "FAQ",
                //     name: "FAQ"
                // },
                // {
                //     label: "Terms",
                //     name: "Terms"
                // },
            ],
        };
    },
};
</script>

<style scoped>
.site-icon {
    font-size: clamp(18px, 2.2vw, 24px);
    display: flex;
    cursor: pointer;
    align-items: center;
    column-gap: 12px;
}

.site-logo{
    width: clamp(70px,8.5vw,100px);
}
.lang-container {
    display: flex;
    align-items: center;
    padding: 0px 4px;
    background: #162045;
    border-radius: 4px;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    width: 100%;
}

.username-container {
    display: flex;
    column-gap: 6px;
    height: 100%;
    align-items: center;

}

.profile-group {
    cursor: pointer;
    min-height: 40px;
    display: flex;
    column-gap: 8px;
    max-width: 283px;
    flex-grow: 1;

}

.user-profile {
    display: flex;
    align-items: center;
    background: #162045;
    border-radius: 4px;
    flex-grow: 1;
    justify-content: space-between;
    column-gap: 10px;
    padding-right: 7px;
}

.user-fullname {
    color: #FCFCFC;
}

.user-brief-name {
    background: linear-gradient(225deg, #18D5FF -14.29%, #6E12B6 52.07%, #FF00A8 112.52%);
    border-radius: 4px 0px 0px 4px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    height: 100%;
    font-size: clamp(15px, 1.6vw, 18px);
}

.navbar {
    position: fixed;
    padding: 12px 24px;
    z-index: 100;
    display: flex;
    width: 100%;
    white-space: nowrap;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg,
            rgba(203, 203, 203, 0.6) 0%,
            rgba(85, 85, 208, 0.4) 0.01%,
            rgba(0, 0, 48, 0.52) 49.79%,
            rgba(153, 153, 252, 0.4) 100%);
}

.btns>a {
    flex-grow: 1;
}

.links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    color: #F8F4FF;
    max-width: 880px;
    flex-grow: 1;

}

.btns {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    flex-grow: 1;
    max-width: 322px;
}

.login-btn {
    background: rgba(255, 255, 255, 0.58);
}

.gradiant-font {
    background: linear-gradient(225deg,
            #18d5ff -14.29%,
            #6e12b6 52.07%,
            #ff00a8 112.52%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.link {
    font-size: clamp(16px, 1.7vw, 18px);
    cursor: pointer;
}

.signup-btn {
    background: linear-gradient(90deg,
            #1a97bf 2.72%,
            #721ac9 28.48%,
            #ab0ae3 62.88%,
            #c102c5 78.84%,
            #bb1396 100%);
    color: #fcfcfc;
}

.arrow-icon {
    fill: #fcfcfc;
    cursor: pointer;
    width: 14px;
}

.btn {
    width: 100%;
    border-radius: 8px;
    padding: 5px 16px;
    font-size: clamp(13px, 1.5vw, 16px);
}

.lang-container {
    display: flex;
    align-items: center;
    padding: 0px 4px;
    background: #162045;
    border-radius: 4px;
    cursor: pointer;
}

@media(max-width:780px) {
    .link {
        display: none;
    }

    .lang-container {
        display: none;
    }
}

@media(max-width:400px) {
    .site-title {
        display: none;
    }

}
</style>
