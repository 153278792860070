<template>
<hooper class="carousel" :infiniteScroll="true" :progress="true" :autoPlay="true" :playSpeed="1500" :rtl="state.lang=='fa'" :settings="hooperSettings">
    <slide v-for="(crypto,index) in cryptos " :key='index'>
        <div class="slide-container">
            <div class="card">
                <div class="card-body">
                      <div class="head">
                    <div class="coin-icon-container" :id="crypto.relatedCoin">
                        <img class="coin-icon" :src="require('../../../../public/coins/'+crypto.relatedCoin+'.png')" />
                        <img class="coin-icon-blur" :src="require('../../../../public/coins/'+crypto.relatedCoin+'.png')" />
                    </div>
                    <div class="head-details">
                        <div class="head-detail">
                            <span class="coin-title">{{crypto.relatedCoin}}</span>
                            <span :class="{'change-percent':true,negative:crypto.last24HourChange<0,positive:(crypto.last24HourChange>=0)}">
                                <span v-if="crypto.last24HourChange> 0">+</span> <span v-if="crypto.last24HourChange< 0">-</span>
                                {{$toLocal(Math.abs(crypto.last24HourChange),2)}}%
                            </span>
                        </div>
                        
                        <div class="head-detail global-price">
                            <span>{{language.global}}</span>
                            <span>{{$toLocal(crypto.lastPrice,2)}}</span>
                        </div>

                    </div>

                </div>
                <div class="prices">
                    <div class="price">
                        <span class="price-label">{{language.bought}}</span>
                        <span class="price-value">{{$toLocal(crypto.buyPrice,2)}}</span>
                    </div>
                    <div class="price">
                          <span class="price-label">{{language.sold}}</span>
                         <span class="price-value">{{$toLocal(crypto.sellPrice,2)}}</span>
                    </div>

                </div>

                </div>
              

            </div>

        </div>

    </slide>

</hooper>
</template>

<script>

import {
    Hooper,
    Slide
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'CryptosCarousel',
    components: {
        Hooper,
        Slide

    },

    methods: {
        async getPrice() {
            let res = await this.$axios.get('/trades/market-info?', {
                params: {
                    weeklyDataIncluded: false
                }
            }).then(response => res = response.content)
            if (res) {

                this.cryptos = res.filter(crypto => crypto.marketType.includes('TETHER'))
            
              
            }
        },
    },
    mounted() {
        this.alive = true
        this.getPrice()
        console.log(this.$languageDict())
    },
    data() {
        return {
            language: this.$languageDict().homepage.cryptoCarousel,
            cryptos: [],
            hooperSettings: {
                itemsToShow: 2,
                centerMode: false,

                breakpoints: {
                    200: {
                        centerMode: true,

                        itemsToShow: 1
                    },
                    630: {
                        centerMode: true,
                        itemsToShow: 2
                    },
                    1000: {

                        itemsToShow: 3,

                    },
                    1400: {
                        itemsToShow: 4,

                    }
                }
            }

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.global-price{
    color: #E0E0E0;
    font-size: clamp(12px,1.3vw,14px);
}
.head{
    display: flex;
    column-gap: 12px;
    align-items: center;
}
.carousel {
    display: flex;
    
    align-items: center;
    z-index: 5;
    height: 166px;
    box-sizing: border-box;
}
.card-body{
     display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 80%;
    height: 100%;


}
.prices{
    font-size: clamp(16px,1.7vw,18px);
      display: flex;
    flex-direction: column;
}
.price{
    display: flex;
    justify-content: space-between;
  
}
.price-label{
    color: #E0E0E0;
}
.price-value{
    color: #F2F2F2;

}
.slide-container {
    padding: 0px 8px;
    display: flex;
    justify-content: center;
    height: 100%;

}
.coin-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.head-details{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 4px;
}

.card {
   
    background: linear-gradient(58.75deg, #1D0678 -12.15%, rgba(79, 52, 187, 0.93) 49.74%, rgba(39, 27, 89, 0.2) 103.67%);
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    height: 100%;

}

.coin-title {
    color: #FFFFFF;
    font-size: clamp(17px, 1.8vw, 20px);
}

.change-percent {
    font-size: clamp(16px, 1.7vw, 18px);
}

.head-detail {
    display: flex;
    color: #E0E0E0;
    font-size: clamp(12px, 1.3vw, 14px);
    flex-grow: 1;
    justify-content: space-between;
}

.positive {
    color: #28F260;

}

.negative {
    color: red;

}
.coin-icon-blur{
    
    position: absolute;
    filter: blur(10px);
    width: 29px;

}
.coin-icon {
    width: 29px;
    z-index: 2;

}
</style>
