<template>
<div class="banner">
    <div class="howling-wolf">
        <img src='../../../assets/images/moon.png' :class="{moon:true ,'moon-final':parallax}" />
        <img src='../../../assets/images/wolf.png' :class="{wolf:true ,'wolf-final':parallax}" />
        <div class="banner-content" :class="{'banner-content-final':parallax}">
            <div class="banner-phrase">{{lang.bannerPhrase}}</div>
            <div class="signup-box">
                <input class="signup-input" :placeholder="lang.inputPlaceHolder" />
                <button class="signup-btn">{{lang.signupBtn}}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Markets',

    data() {
        return {
            lang: this.$languageDict().homepage.banner,
            parallax: false

        }
    },
    mounted() {
        setTimeout(() => {
            this.parallax = true

        }, 1500);
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.banner-phrase {
    font-size: clamp(27px, 3.3vw, 40px);
    background: linear-gradient(91.03deg, #22084A -2.99%, #DE2BD7 34.98%, #2B52DE 68.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold;
    text-fill-color: transparent;
}

.banner-content {
    position: absolute;
    max-width: 411px;
    top:130px;
    z-index: 10;
    text-align: center;
    opacity: 0;
    transition: opacity 2s;

}

.banner-content-final {
    opacity: 1;
}

.signup-box {
    margin-top: 36px;
    background: #060D35;
    border-radius: 8px;
    display: flex;
    height: 48px;
    padding: 4px;
}

.signup-btn {
    font-size: clamp(15px, 1.5vw, 16px);
    font-weight: bold;
    color: #FCFCFC;
    height: 100%;
    flex-grow: 2;
    background: linear-gradient(90deg, #1A97BF 2.72%, #721AC9 28.48%, #AB0AE3 62.88%, #C102C5 78.84%, #BB1396 100%);
    border-radius: 8px;
}

.signup-input {
    font-size: clamp(13px, 1.4vw, 14px);
    flex-grow: 3;
    color: white;
    background: transparent;

}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #BDBDBD;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #BDBDBD;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #BDBDBD;
}

.banner {
    width: 100%;
}

.howling-wolf {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wolf {
    width: 100%;
    margin-top: -60%;
    transition:width 1.5s ease-in-out, margin 1.5s ease-in-out ;
    z-index: 5;
}

.wolf-final {
    /* transform: scale(0.8); */
    width: 74%;
    margin-top: -34%;

}

.moon {
    transition: width 1.5s ease-in-out;
    width: 77%;
    opacity: 0.9;
    filter: blur(1px);

}

.moon-final {
    top: 0px;
    width: 60%;

}
</style>
