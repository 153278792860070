<template>
<div>

    <div class="history-table-title">
        {{lang.transactions}}
    </div>
    <div class="filters">
        <RangeCalendar @input="value=>{startDate=value[0];endDate=value[1]}" />
        <DropFilter class="f-3" @choose="(value)=>{coin=value.value}" :items="coinsList" />

    </div>

    <div class="history-table-container">
        <perfect-scrollbar @ps-y-reach-end="getWithdrawHistory()" ref="scroll">
            <table class="history-table">
                <tr class="history-table-header">
                    <td class="resp-1">{{lang.tableHeaders.time}}</td>
                    <td>{{lang.tableHeaders.coin}}</td>
                    <td class="resp-2">{{lang.tableHeaders.type}}</td>
                    <td>{{lang.tableHeaders.volume}} <span class="tag" v-if="coin">({{$coins[coin].code}})</span></td>
                    <td class="resp-1">{{lang.tableHeaders.transactionID}}</td>
                    <td class="resp-2">{{lang.tableHeaders.status}}</td>
                </tr>
                <tr class="history-table-row" v-for="(transaction,index) in transactions" :key="index">
                    <td class="resp-1">{{$G2J(transaction.createdAtDateTime)}}</td>
                    <td>
                        <div class="coin-title">
                            <span>{{$coins[transaction.relatedCoin].code}}</span>
                        </div>
                    </td>
                    <td class="resp-2" :class="{'depsoit-buy':transType=='DEPSOIT','withdraw-sell':transType=='WITHDRAW'}">{{dict[transType]}}</td>
                    <td dir='ltr'>{{$toLocal(transaction.amount,$decimal[transaction.relatedCoin])}}&nbsp;<span class="tag">{{!coin?$coins[transaction.relatedCoin].code:''}} </span> </td>
                    <td class="resp-2">{{transaction.destinationWalletAddress || '-'}}</td>
                    <td class="resp-1">{{transaction.hashCode || '-'}}</td>
                    <td class="resp-2">
                        <div :style="{color:statusColor(transaction.transactionStatus)}"  class="status">{{lang.statusLabels[transaction.transactionStatus]}}</div>
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>

</div>
</template>

<script>
import RangeCalendar from '../../Tools/RangeCalendar'
import DropFilter from './DropFilter'
import {
    statusLabel,
    statusColor

} from "@/library/reuseableFunction";

export default {
    name: 'Logins',
    components: {
        DropFilter,
        RangeCalendar
    },
    props: {

    },
    watch: {

        coin() {
            this.withdrawList = []
            this.withdrawPage = 0
            this.withdrawLastPage = 1
            this.depositList = []
            this.depsoitPage = 0
            this.depsoitLastPage = 1
            this.getWithdrawHistory();
            this.getDepositHistory();
        }
    },
    computed: {
        transactions() {
            return this.transType == 'WITHDRAW' ? this.withdrawList : this.depositList
        },
        coinsList() {
            let coins = Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                return {
                    label: coin[1].code,
                    value: coin[0]
                }
            })
            coins.unshift({
                label: 'Coin',
                value: ''
            })
            return coins
        },
        coinsQuery() {
            let coinsStr = '?size=20'
             if(this.startDate || this. endDate)
                coinsStr+='&startDate='+this.startDate+'T00:00:00'+'&endDate='+this.endDate+'T23:59:59'
       
            if (!this.coin) {
                Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                    coinsStr += '&relatedCoin=' + coin[0]
                })
                return coinsStr
            } else
                return coinsStr + '&relatedCoin=' + this.coin
        },

        statusLabel,
        statusColor
    },
    methods: {
        filterTransactions() {
            return this.transactions.filter(transaction => {
                if (transaction.transType == this.transType) {
                    if (this.coin != 'TOMAN') {
                        if (this.$coinUnit[transaction.coinName] == this.coin.value)
                            return transaction
                    } else
                        return transaction
                }
            })
        },
        async getWithdrawHistory() {
            const address = 'wallets/withdrawal-requests'
            if (this.withdrawPage <= this.withdrawLastPage) { //for scroll loading
                this.withdrawPage++ //for scroll loading
                this.loading = true
                let params = {
                    page: this.withdrawPage,
                }

                const [res, ] = await this.$http.get(`${address}${this.coinsQuery}`, {
                    params
                })
                if (res) {
                    this.withdrawList = [...this.withdrawList, ...res.content]
                    this.withdrawLastPage = res.totalPages

                }
                this.loading = false

            }

        },
        async getDepositHistory() {

            const address = 'wallets/deposit'
            if (this.depsoitPage <= this.depsoitLastPage) { //for scroll loading
                this.depsoitPage++ //for scroll loading
                this.loading = true
                let params = {
                    page: this.depsoitPage,
                }

                const [res, ] = await this.$http.get(`${address}${this.coinsQuery}`, {
                    params
                })
                if (res) {
                    this.depositList = [...this.depositList, ...res.content]
                    this.depsoitLastPage = res.totalPages
                }
                this.loading = false

            }
        },
    },
    mounted() {
        this.getWithdrawHistory()
        this.getDepositHistory()
    },
    data() {
        return {
             endDate:'',
            startDate:'',
            lang: this.$languageDict().history,
            withdrawList: [],
            depositList: [],
            loading: false,
            depsoitPage: 0,
            withdrawPage: 0,
            depsoitLastPage: 1,
            withdrawLastPage: 1,
            transType: 'DEPSOIT',
            dict: {
                'WITHDRAW': 'برداشت',
                'DEPSOIT': 'واریز'
            },
            coin: ''

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.left-filter {
    flex-grow: 1;
    max-width: 280px;
}

.mar {
    width: 600px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
