<template>
<div class="page-container" @click.self="$emit('close')">
    <div class="modal-card">
        <div class="card-header">
            <slot class="card-title" name="title">

            </slot>
            <img class="icon" @click="$emit('close')" src="../../assets/icons/closecard.svg" />
        </div>

        <div class="card-body">
            <slot name='body'>

            </slot>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'ModalCard',
    props: {
        msg: String
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.modal-card {
    position: fixed;
    width: 90%;
    // top: 100px;
    flex-grow: 1;
    max-width: 414px;

    margin-top: 70px;
    z-index: 1000;
    background: #030920;
    border-radius: 8px;
    padding: clamp(15px, 2vw, 24px);
}

.modal-card > div {
    width: 100%;
}


.card-header {
    color: #F2F2F2;
    position: relative;
    font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: clamp(18px, 2.1vw, 24px);
    align-items: center;
}

.card-body {
    margin-top: 23px;
    display: flex;
    // align-items: center;
    row-gap: 20px;
    flex-direction: column;
}

.page-container {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: rgba(26, 151, 191, 0.2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000
}

.icon {
    cursor: pointer;
    position: absolute;
    right: 0px;
}
</style>
