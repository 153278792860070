<template>
<div class="section logins">
    <div class="section-title">{{lang.sectionTitle}}</div>
    <div class="text">{{lang.text}}</div>

    <div class="history-table-container">
        <perfect-scrollbar @ps-y-reach-end="check()" ref="scroll">
            <table class="history-table">
                <tr class="history-table-header">
                    <td >{{lang.tableHeaders.time}}</td>
                    <td class="history-resp-3">{{lang.tableHeaders.platform}}</td>
                    <td class="history-resp-2">{{lang.tableHeaders.ipAddress}} </td>
                    <td class="history-resp-1">{{lang.tableHeaders.browser}}</td>
                    <td class="history-resp-3">{{lang.tableHeaders.deivce}}</td>
                    <td>{{lang.tableHeaders.status}}</td>
                </tr>
                <tr class="history-table-row" v-for="(login,index) in logins" :key="index">
                    <td >{{$G2J(login.loginRequestedDateTime)}}</td>
                    <td class="history-resp-3">{{login.osType}}</td>
                    <td class="ip history-resp-2">{{login.ipAddress}}</td>
                    <td class="history-resp-1">{{login.browser}}</td>
                    <td class="history-resp-3">{{login.deviceType}}</td>
                    <td class="status" :class="{'history-green':login.isSuccessful,'history-red':!login.isSuccessful}">
                        {{lang.successCons[login.isSuccessful]}}
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>
</div>
</template>

<script>
export default {
    name: 'LoginsHistory',
    data() {
        return {
            lang: this.$languageDict().settings.logins,

            lastPage: 1,
            page: 0,
            deviceDict: {
                "MOBILE": "موبایل",
                "DESKTOP": "دسکتاپ"
            },
            logins: [],

        }
    },
    methods: {
        async check() {
            if (this.page <= this.lastPage) { //for scroll loading
                this.page++ //for scroll loading
                const r = await this.$axios("/users/account?imageNeeded=false")
                this.verifyMethod = r.baseDTO.towStepAuthenticationType
                console.log(this.verifyMethod)
                this.state.loading = true
                const res = await this.$axios.get('/users/login-history', {
                    params: {
                        size: 20,
                        page: this.page
                    }
                })
                this.lastPage = res.totalPages
                this.logins = [...this.logins, ...res.content]

            }
        },
        load() {
            if (this.page <= this.lastPage) {
                setTimeout(() => {
                    this.check()
                }, 1000)
            }
        }
    },
    mounted() {
        this.check()
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.logins {
    margin-top: 24px;
}

.history-table-container {
    max-height: 310px;
    margin-top: 32px;
}
</style>
