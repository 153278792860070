<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        {{lang.modalTitle}}
    </template>
    <template #body>
        <DropDown @choose="value=>data.type=value" :defaultValue='data.type' :items="[{label:lang.types[types[0]],value:types[0]},{label:lang.types[types[1]],value:types[1]}]" />
        <LabelInput :label="lang.titleLabel" @input="(value)=>data.subject=value" />
        <textarea :placeholder="lang.textAreaPlaceHolder" :rows="5" class="text-area" v-model="data.text" />
        <button :disabled=" (!data.text || !data.subject) " :class="{'submit-btn':true,disabled:(!data.text || !data.subject)}" @click="submit()">تایید </button>
    </template> 
</ModalCard>
</template>

<script>
import DropDown from '../Tools/DropDown'
import ModalCard from './ModalCard'
export default {
    name: 'AddTicket',
    components: {
        ModalCard,
        DropDown

    },

    methods: {
        submit() {
            let formData = new FormData
            if (this.ticket.type)
                formData.append('text', this.data.text)
            else
                for (let field in this.data) {
                    formData.append(field, this.data[field])
                }
            console.log(formData)
            this.$emit('addTicket', formData)
            this.$emit('modalToggle', '')

        },

    },
    data() {
        return {
            lang: this.$languageDict().support.addTicketModal,
            data: {
                type: 'GENERAL',
                subject: '',
                text: '',
            },
            types: [
                'TECHNICAL',
                'GENERAL'
            ]

        }
    }
}
</script>

<style scoped>
.submit-btn {
    background: linear-gradient(90deg, #1A97BF 2.72%, #721AC9 28.48%, #AB0AE3 62.88%, #C102C5 78.84%, #BB1396 100%);
    border-radius: 8px;
    height: 35px;
    width: 100%;
    max-width: 250px;
    margin: auto;
    color: #FCFCFC;
    font-size: clamp(14px, 1.5vw, 16px);
}

.text-area {
    background: #060D35;
    border-radius: 4px;
    color: #FCFCFC;
    outline: none;

}
</style>
