<template>
<div class="guide-article ">
    <div class="head">
        <img class="wolf" src='../../../assets/images/magnify-wolf.png' />
        <div class="head-search-sec">
            <div class="url">
                Guide
                <span v-if="openedCategory">
                    <inline-svg :src="require('../../../assets/icons/arrowdown.svg')" class="arrow-icon url-arrow" />
                    {{openedCategory}}
                </span>
                <span v-if="openedArticle" class="article-url">
                    <inline-svg :src="require('../../../assets/icons/arrowdown.svg')" class="arrow-icon url-arrow" />
                    {{openedArticle}}
                </span>
            </div>
            <SearchBox />

        </div>
        <img class="wolf" src='../../../assets/images/reading-wolf.png' />

    </div>
    <div class="main">
        <div class="categories">
            <div class="sec-title">{{lang.categoriesSecTitle}}</div>
            <div class="category" v-for="(category,index) in lang.categories" :key="index" @click="$router.push('/guide/'+category.name)">
                <div class="category-title">
                    <img class="icon" :src="require('../../../assets/icons/'+category.name+'.svg')" alt="">
                    {{category.label}}
                </div>
                <inline-svg :class="{'open-arrow':category.name!=openedCategory}" :src="require('../../../assets/icons/arrowdown.svg')" class="arrow-icon" />
            </div>

        </div>
        <div class="article-content" v-if="openedArticle">
            <div class="category-head-title">{{lang.categories.find(c=>c.name==openedCategory).articles.find(a=>a.name==openedArticle).label}}</div>
            {{ lang.categories.find(c=>c.name==openedCategory).articles.find(a=>a.name==openedArticle).text}}
        </div>
        <div class="articles" v-else>
            <div class="category-head-title">{{openedCategory}}</div>

            <div class="article" v-for="(article,index) in lang.categories.find(c=>c.name==openedCategory).articles" :key="index" @click="$router.push('/guide/'+openedCategory+'/'+article.name)">
                {{article.label}}
                <inline-svg :class="{'open-arrow':true}" :src="require('../../../assets/icons/arrowdown.svg')" class="arrow-icon" />
            </div>

        </div>

    </div>

</div>
</template>

<script>
import SearchBox from '../../Tools/SearchBox'
export default {
    name: 'GuideArtice',
    components: {
        SearchBox
    },
    computed: {
        openedCategory() {
            return this.$route.params.category

        },
        openedArticle() {
            return this.$route.params.article

        }
    },

    data() {
        return {
            lang: this.$languageDict().guide,

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.url {
    color: white;
    font-size: clmap(16px, 1.7vw, 18px);
    margin-bottom: 20px;
}

.article-url {
    color: #BDBDBD;
}
.head-search-sec {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.article-content {
    flex: 3;
}
.guide-article {
    width: 85%;
    margin-top: 88px;
    padding-bottom: 88px;
}
.wolf {
    max-width: 40%;
}

.url-arrow {
    transform: rotate(-90deg);
    width:16px;
}

.article-content {
    font-size: clamp(15px, 1.6vw, 18px);
    color: #FCFCFC;

}

.icon {
    height: 32px;
    width: 32px;
}

.sec-title {
    white-space: nowrap;
    color: #FFFFFF;
    font-size: clamp(18px, 2.1vw, 24px);
    margin-bottom: 24px;
}

.category-head-title {
    white-space: nowrap;
    color: #FFFFFF;
    font-size: clamp(26px, 3.1vw, 36px);
    margin-bottom: 12px;
}

.categories {
    display: flex;
    flex: 1;
    max-width: clamp(140px, 24vw, 290px);
    flex-direction: column;
    row-gap: 28px;

}

.articles {
    display: flex;
    flex-direction: column;
    row-gap: 28px;

}

.arrow-icon {
    fill: #fcfcfc;
    cursor: pointer;
}

.article {
    font-size: clamp(15px, 1.6vw, 18px);
    color: #FCFCFC;
    white-space: nowrap;

    cursor: pointer;
}

.head {
    align-items: center;
    background: radial-gradient(42.49% 63.74% at 50% 50%, #2A1A8F 0%, #020E38 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 30px;
    column-gap: 30px;
    min-height: 332px;
}

.main {
    padding-top: 48px;
    display: flex;
    column-gap: clamp(40px, 6vw, 80px);
}

.open-arrow {
    transform: rotate(-90deg);
}

.category {
    white-space: nowrap;
    cursor: pointer;
    column-gap: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-title {
    font-size: clamp(14px, 1.5vw, 16px);
    color: #F2F2F2;
    column-gap: 12px;
    display: flex;
    align-items: center;

}

@media(max-width:600px) {
    .icon {
        display: none;
    }
}
</style>
