<template>
<div class="app" :key="state.lang" :class="{'english':state.lang=='en','persian':state.lang=='fa'}">
    <PublicNavbar v-if="!$route.path.includes('Panel')" />
    <LoadingPage v-if="state.loading" />
    <router-view></router-view>
</div>
</template>

<script>
import PublicNavbar from "./components/Public/PublicNavbar";
import LoadingPage from "./components/Tools/LoadingPage";
export default {
    name: "App",
    components: {
        PublicNavbar,
        LoadingPage,
    },
    mounted() {
        window.addEventListener('storage', event => {
            console.log(event)
        });

    }
};
</script>

<style>
.app {
    background: rgba(2, 14, 56, 1);
    min-height: 100vh;
    flex-direction: column;
    display: flex;
    width: 100%;
    align-items: center;
}
</style>
