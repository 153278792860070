<template>
<div class="guide-content">
    <div class="categories">
        <div class="category" v-for="(category,index) in lang.categories" :key="index">
            <div class="category-title">
                <img class="icon" :src="require('../../../assets/icons/'+category.name+'.svg')" alt="">
                {{category.label}}
            </div>
            <div class="articles">
                <a class="article" v-for="(article,index) in category.articles" :key="index" :href="`Guide/${category.name}/${article.name}`" @click.prevent="$router.push(`Guide/${category.name}/${article.name}`)">
                    {{article.label}}
                </a>
            </div>

        </div>

    </div>
    <div class="questions">
        <div v-for="(question,index)  in questions" :key="index" :class="{'open-question':openedIndex==index}" class="question" @click="openedIndex=(openedIndex==index)?null:index">
            <div class="question-bar">
                <span class="title">{{question.question}}</span>
                <inline-svg :src="require('../../../assets/icons/arrowdown.svg')" class="arrow-icon" />

            </div>
            <div v-if="openedIndex==index" class="answer">
                <div>{{question.answer}}</div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'GuideContent',

    data() {
        return {
            lang: this.$languageDict().guide,
            questions:[],
            openedIndex: null,


        }
    },
    mounted() {
        this.getFaq()

    },
    methods: {
        async getFaq() {
            this.questions = await this.$axios.get('faq' , {
                params:{
                    language:'ENGLISH'
                }
            }).then(response => this.questions = response)

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.categories {
    width: 100%;
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
}

.icon {
    width: 50px;
    height: 50px;
}

.category-title {
    font-size: clamp(20px, 2.1vw, 24px);
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    column-gap: 12px;
    margin-bottom: 15px;
}

.articles {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
}

.article {
    color: #FCFCFC;
    font-size: clamp(16px, 1.7vw, 18px);
}

.category {
    flex-grow: 1;
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 8px;
    padding: 16px;
    padding-bottom: 40px;
}

.article:hover {
    color: #CB07C3;
    cursor: pointer;
}

.guide-content {
    width: 85%;
    padding-bottom: 80px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.answer{
    margin-top: 16px;
}
.page-title {
    color: #FFFFFF;
    font-size: clamp(24px, 3vw, 36px);
}

.public-page {
    background: radial-gradient(42.49% 63.74% at 50% 50%, #2A1A8F 0%, #020E38 100%);
}

.question {
    background: #18223D;
    cursor: pointer;
    flex-basis: 450px;
    flex-grow: 1;
    border-radius: 4px;
    padding: 16px 24px;
    color: #F2F2F2;
    font-size: clamp(14px, 1.5vw, 16px);
}

.arrow-icon {
    fill: #fcfcfc;
    cursor: pointer;
    transform: rotate(-90deg);
    min-width: 20px;
}

.question:hover {
    background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
    ;
}

.question:hover .arrow-icon {
    transform: initial;
}

.open-question {
    background: #030920;
}

.open-question  .title{
    color:#CB07C3 ;
}
.open-question  .arrow-icon {
    fill:#CB07C3 ;
    transform: rotate(-180deg);
}
.questions {
    width: 100%;
    border: 1px solid;
    flex-wrap: wrap;
    margin-top: 45px;
    display: flex;
    row-gap: 16px;
    column-gap: 16px;
}

.question-bar {
    column-gap: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
