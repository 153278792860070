<template>
<div class="coin-details" v-if="crypto.relatedCoin">
    <div class="head-row">
        <div class="coin-title">
            <img class="coin-icon" :src="require('../../../../public/coins/'+crypto.relatedCoin+'.png')" />
            <span class="capital">{{$coin2Snake[crypto.relatedCoin].toLowerCase().split('_').join(' ')}} </span>
        </div>
        <span class="rank">{{lang.rank}} #1</span>
        <div class="btns">
            <button class="btn  trade-btn capital" @click="$router.push('/Panel/Trade/TOMAN/'+$coin2Snake[crypto.relatedCoin])">{{lang.tradeBtn}} {{$coin2Snake[crypto.relatedCoin].toLowerCase().split('_').join(' ')}}</button>
            <button class="btn  buy-btn capital"  @click="$router.push('/Panel/Exchange')">{{lang.buyBtn}} {{$coin2Snake[crypto.relatedCoin].toLowerCase().split('_').join(' ')}}</button>
        </div>
    </div>
    <div class="price-details">
        <div class="price-and-change">
            <div class="prices">
                <span class="usd-price">{{$toLocal(crypto.lastPrice,2)}}&nbsp;Tomans</span>
                <span class="usdt-price">{{$toLocal(crypto.lastPrice,2)}}&nbsp;USDT</span>
                <span class="usdt-tomans">USDT/Tomans:&nbsp;{{$toLocal(tetherPrice,0)}}&nbsp;Tomans</span>
            </div>
            <div class="change" :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}">
                <span v-if="crypto.last24HourChange>0">+</span><span v-if="crypto.last24HourChange<0">-</span>
                {{Math.abs(crypto.last24HourChange).toFixed(2)}} %
            </div>
        </div>
        <div class="cap-vol">
            <div class="price-column">
                <div class="title-change">
                    <span class="column-title">{{lang.marketCap}}</span>
                    <div class="cap-change" :class="{'dark-red':crypto.last24HourChange<0,'dark-green':crypto.last24HourChange>0}">
                        <span v-if="crypto.last24HourChange>0">+</span><span v-if="crypto.last24HourChange<0">-</span>
                        {{Math.abs(crypto.last24HourChange).toFixed(2)}} %
                    </div>
                </div>
                <div class="cap-price">{{$toLocal(crypto.maxPrice,0)}}</div>
                <div class="cap-tag-price">Tomans</div>
            </div>
            <div class="price-column">
                <div class="title-change">
                    <span class="column-title">{{lang.marketCap}}</span>
                    <div class="cap-change" :class="{'dark-red':crypto.last24HourChange<0,'dark-green':crypto.last24HourChange>0}">
                        <span v-if="crypto.last24HourChange>0">+</span><span v-if="crypto.last24HourChange<0">-</span>
                        {{Math.abs(crypto.last24HourChange).toFixed(2)}} %
                    </div>
                </div>
                <div class="cap-price">{{$toLocal(crypto.last24HourVolume,0)}}</div>
                <div class="cap-tag-price">Tomans</div>
            </div>
        </div>
    </div>
    <div class="social-details-bar">
        <div class="social-detail">
            <span class="social-label">{{lang.socialMedia}}</span>
            <span class="social-icons">
                <div class="icon-container">
                    <img src='../../../assets/icons/twitter.svg' />
                </div>
                <div class="icon-container">
                    <img src='../../../assets/icons/instagram.svg' />
                </div>
                <div class="icon-container">
                    <img src='../../../assets/icons/telegram.svg' />
                </div>
            </span>
        </div>
        <div class="social-detail">
            <span class="social-label">{{lang.website}}</span>
            <u class="social-link"><a>Bitcoin.com</a></u>
        </div>
        <div class="social-detail">
            <span class="social-label">{{lang.explorers}}</span>
            <u class="social-link"><a>blockchain.com</a></u>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'Coin',
    props: ['crypto'],
    data() {
        return {
            lang: this.$languageDict().coin.coinDetails,
            tetherPrice:'',
        }
    },
     methods:{
       async getTether() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                    market_type:"TETHER_TOMAN"
                }
            }).then(response => res = response.content)
            if (res) {
                this.tetherPrice = res[0].lastPrice
            }
        },
    },
    mounted(){
        this.getTether()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.social-details-bar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 24px;
    background: #18223D;
    border-radius: 8px;
    color: #F2F2F2;
    font-size: clamp(15px, 1.6vw, 18px);
    margin-top: 30px;
    column-gap: 14px;
    row-gap: 16px;
}

.social-icons{
    display: flex;
    column-gap: 16px;
}
.link{
    font-size: clamp(15px,1.6vw,18px);

}
.social-label {
    font-weight: bold;

}
.social-detail{
    display: flex;
    column-gap: 24px;
}
.icon-container {
    width: 32px;
    height: 32px;
    background: #6B6B6B;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.coin-details {
    white-space: nowrap;
}

.usd-price {
    color: #FFFFFF;
    font-size: clamp(18px, 2.4vw, 28px);
    font-weight: bold;
}

.cap-vol {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 20px;
    flex-grow: 1;
    max-width: 510px;
    flex-wrap: wrap;
}

.usdt-price {
    font-size: clamp(16px, 1.7vw, 18px);
    color: #F2F2F2;
}

.price-column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 220px;
}

.title-change {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
}

.cap-price {
    font-size: clamp(20px, 2.3vw, 28px);
    color: #F2F2F2;
}

.cap-tag-price {
    color: #BDBDBD;
    font-size: clamp(15px, 1.6vw, 18px);

}

.column-title {
    color: #F2F2F2;
    font-size: clamp(16px, 1.7vw, 18px);
    font-weight: bold;
}

.usdt-tomans {
    color: #E0E0E0;
    font-size: clamp(13px, 1.3vw, 14px);
}

.price-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    column-gap: 25px;
    flex-wrap: wrap;
    row-gap: 36px;
}

.coin-title {
    font-size: clamp(22px, 2.5vw, 28px);
    color: #F2F2F2;
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.price-and-change {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    column-gap: 8px;
    max-width: 580px;
}

.prices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 8px;
}

.dark-green {
    color: #219653;

}

.dark-red {
    color: rgb(143, 4, 4);
}

.cap-change {
    font-size: clamp(16px, 1.7vw, 18px);
}

.green {
    color: #28F260;
    background: rgba(47, 61, 43, 0.91);


}

.red {
    color: red;
    background: rgb(40, 33, 33);
}

.rank {
    color: #E0E0E0;
    font-size: clamp(15px, 1.6vw, 18px);
}

.coin-icon {
    width: 48px;
}

.head-row {
    margin-top: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;

}

.trade-btn {
    border: 2px solid #CB07C3;
    background: transparent;
}

.buy-btn {
    background: linear-gradient(90deg, #1A97BF 2.72%, #721AC9 28.48%, #AB0AE3 62.88%, #C102C5 78.84%, #BB1396 100%);
}

.change {
    border-radius: 4px;
    padding: 2px 6px;
    font-size: clamp(18px, 2.4vw, 28px);
    font-weight: bold;
}

.btn {
    border-radius: 8px;
    flex-grow: 1;
    font-size: clamp(14px, 1.5vw, 16px);
    color: #FCFCFC;
    height: 35px;
    min-width: 70px;

}

.btns {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    flex-grow: 1;
    max-width: 388px;
}
</style>
