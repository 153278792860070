<template>
  <div class="chart"> 
       <apexchart id='chart' type="line" width="90" height='42' ref="chart" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props:{
      data:Array
  },
  data(){
      return{
         series: [{
            data: []
          }],
          chartOptions: {
            chart: {
             
              type: 'line',
                sparkline: {
                    enabled: true,
                }
            },
                stroke: {
                width: 2,
                curve: 'smooth'
            },
            tooltip:{
                enabled:false
            },
            
          
                 colors:['#FFC04F']
            //      fill: {
            //         type: 'gradient',
            //         gradient: {
            //             shade: 'dark',
            //             colorStops: [ [
            //                 {
            //                     offset: 0,
            //                     color: '#FF951F',
            //                     opacity: 1
            //                 }, {
            //                     offset: 50,
            //                     color: '#F6BD68',
            //                     opacity: 1
            //                 }, {
            //                     offset:100 ,
            //                     color: '#FF951F',
            //                     opacity: 1
            //                 },
            //             ]],
            //             shadeIntensity: 1,
            //             type: 'horizontal',
            //             opacityFrom: 1,
            //             opacityTo: 1,
                       
            //         },
            // },
        
      
   
          },
      }
  },
  mounted(){
      this.series=[{
          data:this.data
      }]
  }
//   mounted: function () {
//         //   var me = this
//           window.setInterval(function () {
//             getNewSeries(lastDate, {
//               min: 10,
//               max: 90
//             })
            
//             this.$refs.chart.updateSeries([{
//               data: this.data
//             }])
//           }, 1000)
        
//           // every 60 seconds, we reset the data to prevent memory leaks
//           window.setInterval(function () {
            
//             this.$refs.chart.updateSeries([{
//               data:this.data
//             }], false, true)
//           }, 60000)
//         },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart{
    max-height: 40px;
}
</style>
