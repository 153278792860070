<template>
<div class="public">
    <div class="publics">
        <Homepage class="home-page " v-if="$route.path=='/'" @signupModalToggle="$emit('signupModalToggle')" />
        <Guide v-if="$route.name=='Guide'" />
        <GuideArticle v-if="$route.name=='GuideArticle' || $route.name=='GuideCategory'" />
        <FAQ v-if="$route.name=='FAQ'" />
        <Terms v-if="$route.name=='Terms'" />
        <Markets v-if="$route.name=='Markets'" />
        <Coin v-if="$route.name=='Coin'" />
   

    </div>
    <Footer />

</div>
</template>

<script>
import Homepage from './Homepage/Homepage'
import GuideArticle from './Guide/GuideArticle'
import Guide from './Guide/Guide'
import FAQ from './FAQ'
import Terms from './Terms'
import Coin from './Coin/Coin'
import Markets from './Markets'
import Footer from './Footer'

export default {
    name: 'Public',
    components: {
        Homepage,
        Footer,
        Guide,
        GuideArticle,
        FAQ,
        Terms,
        Coin,
        Markets,
    },
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.public {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;

}
.publics{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

}
.public > div{
    width: 100%;
}
.publics > div{
    height: 100%;
}
// .chat-icon-container{
//   display:flex;
//   position: fixed;
//   cursor: pointer;
//   right: 8vw;
//   background:var(--public-chat-icon-bg);
//   stroke:var(--public-chat-icon);
//   top: 80vh;
//   align-items: center;
//   justify-content: center;
//   height: 48px;
//   width: 48px;
//   z-index: 10;
//   border-radius: 50%;
// }
</style>
