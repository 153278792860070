<template>
<div class="switch" :class="{shadow:type2}" @click="choose()">
    {{items[choiceIndex]}}
    <inline-svg :src="require('../../assets/icons/arrowdown.svg')" class="arrow-icon" />
</div>
</template>

<script>
export default {
    name: 'ToggleComp',
    props: {
        items: Array,
        defaultValue: String,
        type2: Boolean

    },
    data() {
        return {
            choiceIndex: this.items.indexOf(this.items.find(item => item == this.defaultValue) || this.items[0])
        }
    },
    methods: {
        choose() {
            if (this.choiceIndex < (this.items.length - 1))
                this.choiceIndex++
            else
                this.choiceIndex = 0
            this.$emit('change', this.items[this.choiceIndex])
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.arrow-icon {
    fill: white;
    transform: rotate(-90deg);
    width: 13px;
}

.switch {
    cursor: pointer;
    display: flex;
    justify-content: center;
    column-gap: 9px;
    align-items: center;
    min-width: 60px;
    padding: 0px 4px;
    height: 25px;
    background: #121834;
    color: #FFFFFF;
    box-shadow: 1px 1px 2px rgba(64, 68, 87, 0.3), -1px -1px 2px rgba(22, 24, 31, 0.5), inset -1px 1px 2px rgba(22, 24, 31, 0.2), inset 1px -1px 2px rgba(22, 24, 31, 0.2), inset -1px -1px 2px rgba(64, 68, 87, 0.9), inset 1px 1px 3px rgba(22, 24, 31, 0.9);
    font-size: clamp(13px, 1.3vw, 14px);
}

.shadow {
    background: #121834;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0px 8px;

}
</style>
