<template>
<div class="section">
    <div class="section-title">{{lang.sectionTitle}}</div>
    <form class="changepass-form" @submit.prevent="submit">
        <LabelInput @input="value=>oldPassword=value" type='password' :label="lang.currentPass" />
        <LabelInput @input="value=>newPassword1=value" type='password' :label="lang.newPass" />
        <LabelInput @input="value=>newPassword2=value" type='password' :label="lang.confirmNewPass" />
        <button class="submit-btn" @click.prevent=""
        :class="{disabled:disabled}" :disabled="disabled"
         type="submit">{{lang.submitBtn}}</button>

    </form>
</div>
</template>

<script>
export default {
    name: 'ChangePass',
    data() {
        return {
            lang: this.$languageDict().settings.changePass,
            oldPassword: '',
            newPassword1: '',
            newPassword2: '',
            passError: '',
            pass1Error: '',
            pass2Error: '',
        }
    },
    computed:{
        disabled(){
            return !this.oldPassword||!this.newPassword1||!this.newPassword2
        }
    },
    methods: {
        async submit() {

            let passSent = {
                oldPassword: this.oldPassword,
                password: this.newPassword1
            }
            await this.check2Pass()
            if (this.newPassword1 && this.newPassword2) {
                if (this.checkPass())
                    this.state.loading = true
                try {
                    console.log(passSent)
                    const res = await this.$axios.post(`/users/change-password`, passSent)
                    if (res.message === "password changed successfully") {
                        this.$error('رمز شما تغییر یافت', '', 'success')
                        this.oldPassword = ''
                        this.newPassword1 = ''
                        this.newPassword2 = ''
                    }
                } catch (error) {
                    if (error.response.data.message === 'Old Password Is Incorrect') {
                        this.passError = 'رمز قبلی اشتباه است.'
                    }
                }
            }
        },
        checkPass() {
            if (!this.oldPassword) {
                this.passError = 'رمز فعلی اجباری';
            } else {
                this.passError = ''
            }
            if (this.newPassword1) {
                if (this.newPassword1.length < 8) {
                    this.pass1Error = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.newPassword1)) {
                    this.pass1Error = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else if (!this.newPassword2) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز اجباری';
                } else if (this.newPassword2 !== this.newPassword1) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز مطابقت ندارد';
                } else {
                    this.pass2Error = ''
                }
            }
            if (!this.passError.length && !this.pass1Error.length && !this.pass2Error.length) {
                return true;
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.oldPassword) || this.oldPassword.length < 8) {
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                console.log(this.oldPassword)
                return Promise.reject('wrong pass : ' + this.oldPassword)
            }
            if (this.newPassword1 !== this.newPassword2) {
                this.$error('اطلاعات وارد شده اشتباه است', 'تکرار رمز اشتباه است')
                return Promise.reject('repeat failed : ' + this.newPassword1 + '!==' + this.newPassword2)
            }
        },
        validPass(password) {
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        }

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.submit-btn {
    background: linear-gradient(90deg, #1A97BF 2.72%, #721AC9 28.48%, #AB0AE3 62.88%, #C102C5 78.84%, #BB1396 100%);
    border-radius: 8px;
    height: 40px;
    padding: 0px 14px;
    color: #FCFCFC;
}
.changepass-form{
    display: flex;
    flex-direction: column;
    row-gap: clamp(24px,2.8vw,32px);
    margin-top:  clamp(16px,2vw,24px);
}
.disabled{
    opacity: 0.7;
}
</style>
