<template>
<div class="details-section">
    <div class="section-header">
        <div class="section-title">
            {{lang.sectionTitle}}
        </div>
        <div class="email-and-status">
            <div class="user-email">{{state.userInfo.email}}</div>
            <div class="account-status" :style="{color:colors[state.userInfo.authenticationType]}">
                {{state.userInfo.authenticationType}}
            </div>

        </div>
    </div>
    <div class="account-level-section">
        <div class="account-level-section-title">{{lang.partTitle}}</div>
        <div class="levels">
            <div class="level" :class="{'active-level':state.userInfo.userAccountLevel==level.userAccountLevel}" v-for="(level,index) in $accountLevel.filter(e => e.userAccountLevel !== 'SUPERVISOR')" :key="index">
                <div class="level-title">
                    <div class="level-number">{{userLevels[level.userAccountLevel].label}}</div>
                    <div class="nd-icon" v-if="index!=3">
                        <div class="nd">nd</div>
                        <img src="../../../assets/icons/medal.svg"/>
                    </div>
                </div>
                <div class="level-percent">{{level.wagePercent}}%</div>
                <div class="level-range">{{index!=3?index>0?$accountLevel[index-1].tradeAmount:0:null}}
                  {{index!=3?'-'+level.tradeAmount:'>'+$accountLevel[index-1].tradeAmount}}</div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'WalletSection',
    props: {},
    data() {
        return {
            lang: this.$languageDict().dashboard.accountDetails,
            colors: {
                'AUTHORIZED': 'green',
                "INFORMATION_CHANGED": 'yellow',
                'INFORMATION_CHANGED_PENDING': 'yellow',
                'CONFIRMED_IDENTITY': 'blue',
                'UNAUTHORIZED': 'red',
                'PENDING': 'blue',
                'NOT_COMPLETE': 'red',
                'UNIDENTIFIED': 'red',

            },
            userLevels: {
                'BRONZE': {
                    label: '3',
                    value: 3
                },
                'SILVER': {
                    label: '2',
                    value: 2
                },
                'GOLD': {
                    label: '1',
                    value: 1
                },
                'PLATINUM': {
                    label: 'VIP',
                    value: 0
                }
                // 'SUPERVISOR': {label:'پنجم',value:5},
            },

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.levels {
    background: rgba(214, 214, 214, 0.08);
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-top:12px;
}
.active-level{
    background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
    color: #F2F2F2!important;
}
.level {
    border-radius: 8px;
    padding: 11px 12px;
    display: flex;
    flex-grow: 1;
    flex-basis: 120px;
    flex-direction: column;
    align-items: center;
    color: #828282;
    row-gap: 8px;

}

.level-number {
    font-size: clamp(25px, 3.2vw, 40px);
}
.level-title{
  display: flex;
  align-items: flex-start;
  column-gap: 1px;
}
.nd {
    font-size: clamp(17px, 1.8vw, 20px);
}
.nd-icon{

}
.level-fee {
    font-size: clamp(15px, 1.6vw, 18px);
}

.level-range {
    font-size: clamp(11px, 1.1vw, 12px);
}

.details-section {
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 8px;
    padding: clamp(12px, 1.5vw, 16px);

}

.section-header {
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
    justify-content: space-between;
}

.user-email {
    font-size: 14px;
    color: #FFFFFF;
}

.account-level-section {
    margin-top: 40px;

}

.account-level-section-title {
    color: #BDBDBD;
    font-size: clamp(16px, 1.7vw, 18px);

}

.email-and-status {
    max-width: 331px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    column-gap: 24px;
    row-gap: 16px;
    flex-wrap: wrap;

    align-items: center;

}

.section-title {
    font-size: clamp(18px, 2vw, 24px);
    color: #FFFFFF;
}

.account-status {
    border: 2px solid;
    border-radius: 8px;
    padding: 7px 20px;
    font-size: clamp(12px, 1.3vw, 14px);
}
</style>
