<template>
<div class="public-page">
    <div class="page-title">{{lang.pageTitle}}</div>
    <div class="questions">
        <div v-for="(question,index)  in questions" :key="index" :class="{'open-question':openedIndex==index}" class="question" @click="openedIndex=(openedIndex==index)?null:index">
            <div class="question-bar">
                <span class="title">{{question.question}}</span>
                <inline-svg  :src="require('../../assets/icons/arrowdown.svg')" class="arrow-icon" />

            </div>
            <div v-if="openedIndex==index" class="answer">
                <div>{{question.answer}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'FAQ',
    data() {
        return {
            lang: this.$languageDict().faq,
            openedIndex: null,
            questions: []
        }
    },
    mounted() {
        this.getFaq()

    },
    methods: {
        async getFaq() {
            this.questions = await this.$axios.get('faq',{
                params:{
                    language:'ENGLISH'
                }
            }).then(response => this.questions = response)

        }
    }
}
</script>

<style scoped>
.answer{
    margin-top: 16px;
}
.page-title {
    color: #FFFFFF;
    font-size: clamp(24px, 3vw, 36px);
}

.public-page {
    background: radial-gradient(42.49% 63.74% at 50% 50%, #2A1A8F 0%, #020E38 100%);
    width: 85%;
}

.question {
    background: #18223D;
    cursor: pointer;
    border-radius: 4px;
    padding: 16px 24px;
    color: #F2F2F2;
    font-size: clamp(14px, 1.5vw, 16px);
}

.arrow-icon {
    fill: #fcfcfc;
    cursor: pointer;
    transform: rotate(-90deg);
    min-width: 20px;

}

.question:hover {
    background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
    ;
}

.question:hover .arrow-icon {
    transform: initial;
}

.open-question {
    background: #030920;
}

.open-question  .title{
    color:#CB07C3 ;
}
.open-question  .arrow-icon {
    fill:#CB07C3 ;
    transform: rotate(-180deg);
}
.questions {
    width: 100%;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.question-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}
</style>
