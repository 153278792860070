<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        {{lang.modalTitle}}
    </template>
    <template #body>
        <div class="modal-text"> {{lang.topText}}</div>
        <LabelInput @input='value=>amount=value' type="number" :label='lang.amountLabel' />
        <div class="credit">
            <div class="credit-label">{{lang.avblLabel}}</div>
            <div><span class="credit-value">{{$toLocal(cash,0)}} </span><span class="credit-tag">Tomans</span></div>
        </div>
        <div>
            <div class="card-select">
                <label class="modal-text">{{lang.numberLabel}}</label>
                <DropDown v-if="banksList" @choose="value=>walletAddress=value" :items="banksList" />
            </div>
            <div class="warning">
                <div v-if="errorHandler">{{errorHandler}}</div>
            </div>
        </div>
        <div class="modal-text" v-if="otpInput"> {{lang.enterCodeText}}</div>
        <div v-if="!otpInput" class="modal-text">{{lang.bottomText}}</div>

        <VerifyCodeInput v-if="otpInput" @change="e=>TwoStep_code=e" @complete="submitWithdrawRequest()" :num="6" />
        <div class="countdown" v-if="otpInput &&  counter" :class="{disabled:disabled}">
            {{Math.floor(countDown/60)}}:{{countDown%60}}
        </div>
        <button class="modal-submit-btn" v-if="!otpInput" @click="withdraw()" :class="{disabled:disabled}" :disabled="disabled">
            {{lang.sendCodeBtn}}
        </button>

        <button v-if="otpInput && countDown===0 && counter" class="modal-submit-btn" @click.prevent="send()">
            {{lang.resend}}
        </button>
        <button class="modal-submit-btn" v-if="countDown>0 && otpInput " :class="{disabled:disabled || !otpInput || countDown==0}" @click="submitWithdrawRequest()" :disabled="disabled || !otpInput || countDown==0">{{lang.confirmBtn}} </button>
    </template>

</ModalCard>
</template>

<script>
import ModalCard from './ModalCard'
import DropDown from '../Tools/DropDown'

export default {
    name: 'AddTicket',
    components: {
        ModalCard,
        DropDown

    },
    props: {

        cash: {
            default: function () {
                return 0
            }
        },
        wage: {
            default: 0
        },

    },

    computed: {
        banksList() {
            if (this.banks)
                return this.banks.map(bank => {
                    return {
                        label: bank.cardNumber,
                        value: bank.cardNumber
                    }
                })
            return false
        },
        cardFilter() {
            if (this.banks)
                return this.banks.filter(card => card.cardNumber.replace('-', '').startsWith(this.regex))
            else
                return []
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.cash)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min))
            // ( !this.enable )
            // (!this.selected_net)
        },
        errorHandler() {
            if ((this.$S2N(this.amount) > this.$S2N(this.cash)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.min)))
                return 'مقدار برداشت باید حداقل' + this.$coins[this.relatedCoin].code + ' ' + this.$toLocal(this.min, 0) + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.max)))
                return 'مقدار برداشت باید حداکثر ' + this.$coins[this.relatedCoin].code + ' ' + this.$toLocal(this.max, 0) + ' باشد '
            else
                return ''

        },

    },
    data() {
        return {
            lang: this.$languageDict().wallets.modals.rialWithdraw,
            banksOptions: false,
            unit: "تومان",
            amount: 0,
            banks: [],
            regex: '',
            walletAddress: '',
            TwoStep_code: '',
            towStepAuthenticationType: '',
            transaction: {
                code: '',
                amount: '',
                relatedCoin: 'TOMAN',
                destinationWalletAddress: ''
            },
            counter: false,
            show: false,
            countDown: 120,
            otpInput: false,
            min: 0,
            max: 0

        }
    },
    methods: {
        chooseCard(cardNumber) {
            this.walletAddress = cardNumber
            this.regex = ''
            this.show = false
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.amount = this.$S2N(this.amount)
            this.transaction.destinationWalletAddress = this.walletAddress
            this.transaction.code = this.TwoStep_code
            const [res, ] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
            if (res) {
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                this.$emit('modalToggle', '')
            }
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.banks = res.content
            this.walletAddress = this.banks[0].cardNumber
            const coinsResponse = await this.$axios.get('/coins/details')
            this.coins = coinsResponse.content
            this.coinData = this.coins.find(coin => coin.coin == 'TOMAN')
            this.wage = this.coinData.tokenTypeDetails[0].withdrawalFee
            this.max = this.coinData.tokenTypeDetails[0].maxWithdraw
            this.min = this.coinData.tokenTypeDetails[0].minWithdraw

            console.log(this.coinData)

        },
        async withdraw() {
            if (!this.disabled && !this.state.loading) {
                const res = await this.$axios.post("/users/send-code")
                if (res.message === "code sent successfully.") {
                    this.otpInput = true
                    this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                    if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
                        this.counter = true
                        this.countDownTimer()
                    }
                }
            }

        },

        async send() {
            this.TwoStep_code = ''

            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
    },
    mounted() {
        this.getData()
    },
}
</script>

<style scoped>
.disabled {

    opacity: 0.7;
}

.text {
    font-size: clamp(12px, 1.3vw, 14px);
    color: var(--modal-text)
}

.warning {
    color: #FF2D78;
    font-size: clamp(11px, 1.1vw, 12px);
    font-family: 'YekanLight';
    display: flex;
    align-items: flex-start;
    column-gap: 7px;
}

.countdown {
    background: #232555;
    border-radius: 4px;
    padding: 4px 6px;
    margin-top: 6px;
    color: white;
}

.card-select {
    display: flex;
    column-gap: 6px;
    align-items: center;
    padding: 10px 0px;

}

.credit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.credit-label {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #F2F2F2;

}

.credit-tag {
    color: #CB07C3;
    font-size: clamp(12px, 1.3vw, 14px);
}

.credit-value {
    font-size: clamp(12px, 1.3vw, 14px);
    font-weight: bold;
    color: #CB07C3;
    text-decoration-line: underline;

}
</style>
