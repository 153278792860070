<template>
  <ModalCard @close="$emit('modalToggle', '')">
    <template #title>
      {{ lang.modalTitle }}
    </template>
    <template #body>
      <div class="modal-text">{{ lang.topText }}</div>
      <div class="btns">
        <button class="login-btn btn" @click="$emit('modalToggle', '')">
          <span class="gradiant-font">{{ lang.cancel }}</span>
        </button>
        <button class="signup-btn btn" @click="$emit('confirm')">
          {{ lang.yes }}
        </button>
      </div>
    </template>
  </ModalCard>
</template>

<script>
import ModalCard from "./ModalCard";
export default {
  name: "CancelMdodal",
  components: {
    ModalCard,
  },
  data() {
    return {
      lang: this.$languageDict().cancelModal,
    };
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.6;
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  column-gap: 10px;
}

.btns > button {
  flex-grow: 1;
}

.cancel {
  background: red;
}
.login-btn {
  background: rgba(27, 152, 192, 0.47);
}

.gradiant-font {
  background: linear-gradient(93.29deg, #f82ea7 1.86%, #a30beb 97.42%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.signup-btn {
  background: linear-gradient(
    90deg,
    #1a97bf 2.72%,
    #721ac9 28.48%,
    #ab0ae3 62.88%,
    #c102c5 78.84%,
    #bb1396 100%
  );
  color: #fcfcfc;
}

.btn {
  flex-grow: 1;
  border-radius: 8px;
  padding: 6px 15px;
  font-size: clamp(14px, 1.5vw, 16px);
}
</style>
