<template>
<div class="markets">
    <div class="market-title">{{language.marketTitle}}</div>
    <table>
        <tr class="table-header">
            <td align="left" width='21%'>{{language.tableHeaders.coin}} </td>
            <td align="center">{{language.tableHeaders.lastPrice}}</td>
            <td class="resp-2" align="center">{{language.tableHeaders.marketCap}}</td>
            <td  align="center">{{language.tableHeaders.change}} </td>
            <td class="resp-1" align="right" width='21%'>{{language.tableHeaders.weeklyChart}}</td>
        </tr>
        <tr class="table-row" v-for="(crypto,index) in cryptos.slice(0,9)" :key="index">
            <td align="left" width='21%'>
                <div class="title">
                    <img class="icon resp-1" :src="require('../../../../public/coins/'+crypto.relatedCoin+'.png')" />
                    <span class="coin-title"> {{crypto.relatedCoin}}</span>
                    <span class="symbol"> {{crypto.relatedCoin}}</span>
                </div>
            </td>
            <td align="center"><span>{{$toLocal(crypto.lastPrice,2)}}</span></td>
            <td class="resp-2" align="center"><span>{{$toLocal(crypto.maxPrice,2)}}</span></td>
            <td align="center"><span class="change" :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}">
                <span v-if="crypto.last24HourChange>0">+</span><span v-if="crypto.last24HourChange<0">-</span>{{Math.abs(crypto.last24HourChange).toFixed(2)}} %</span></td>
            <td class="resp-1 " align="right" width='21%'>
                <CoinChart :data="crypto.lastWeekData" />
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import CoinChart from '../CoinChart'
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'Markets',
    components: {
        CoinChart
    },
    data() {
        return {
            language: this.$languageDict().homepage.markets,
            cryptos: [],
            cryptoName: "",
            toman_tether: "TOMAN",
            filteredCryptos: [],
            loading: false,
            alive: false,
        }
    },
 
    methods: {

        async getMarkets() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: true
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res.filter(crypto => crypto.marketType.includes('TETHER'))
            }
        },
     

    },
  mounted() {
       if (this.state.loop) {
            this.loop1 = new Loop(this.getMarkets, 5000, 'getMarkets')
            this.loop1.start()
        } else {
            this.getMarkets()
        }

    },
    beforeDestroy() {
        this.loop1.stop()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.table-header {
    color: #CACACE;
    font-size: clamp(13px,1.4vw,16px);
}

.table-row {
    color: #FFFFFF;
    font-size: clamp(16px, 1.7vw, 20px);
    border-top: 0.4px solid #787878;
}

.symbol{
    font-size: clamp(13px,1.4vw,16px);
    color: #BDBDBD;
}
.coin-title{
    font-size: clamp(15px,1.6vw,18px);
}


.change {
    display: flex;
    direction: ltr;
    justify-content: center;

}

.markets {
    padding-top: clamp(80px, 12vw, 130px);
    width: 100%;
}

.chart {
    vertical-align: inherit;
    max-height: 60px;
}
.market-title{
    font-size: clamp(20px,2.2vw,24px);
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.title {
    display: flex;
    column-gap: 8px;
    align-items: center;

}


a {
    color: #143B74;
    ;
}



.icon {
    width:32px
}

.green {
    color: rgba(11, 135, 16, 1);

}

.red {
    color: rgba(214, 43, 43, 1);
    ;

}





table {
    border-collapse: collapse;
    background: linear-gradient(95.58deg, rgba(49, 47, 78, 0.38) 0.94%, rgba(86, 78, 179, 0.29) 51.67%, rgba(29, 24, 91, 0.52) 96.15%);
    width: 100%;
    border-radius: 12px;

}
  tr:not(:first-child):hover {
    background: linear-gradient(90.07deg, rgba(93, 79, 180, 0.95) 1.28%, rgba(83, 69, 165, 0.84) 99.22%);
    cursor: pointer;
}
tr:first-child td:first-child {
    padding-left: clamp(30px, 3.8vw, 53px);
}
tr td:first-child {
    padding-left: clamp(14px, 1.4vw, 16px);
}
tr td:last-child {
    padding-right: clamp(14px, 1.4vw, 16px);
}
.table-row td{
    padding: 16px 0px;

}
.table-header td{
    padding: 12px 0px;

}
@media(max-width:900px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:550px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:400px) {
    .resp-3 {
        display: none;
    }
}
</style>
