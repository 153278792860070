<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
        {{lang.modalTitle+' '+$coins[relatedCoin].code}}
    </template>
    <template #body>
        <div v-if="!selected_net" class="crypto-network">
            <div class="modal-text">{{lang.chooseNetText}}</div>
            <div class="toggle-comp">
                <DropDown v-if="netsList" class="base-coin" @choose="(net)=>{cryptoNetworkIndex=netsList.indexOf(netsList.find(item=>item.value==net))}" :items="netsList" />
            </div>
            <button class="modal-submit-btn" @click="selectNet()">{{lang.confirmBtn}}</button>
        </div>
        <template v-else>

            <div class="modal-text">{{lang.topText}}</div>

            <LabelInput :disabled="otpInput" :label="lang.amountLabel" @input="value=>amount=value" />
         
            <div class="credit">
                <div class="credit-label">{{lang.avblLabel}}</div>
                <div ><span class="credit-value">{{$toLocal(credit,$decimal[relatedCoin])}}</span>&nbsp;<span class="credit-tag">{{$coins[relatedCoin].code}}</span></div>
            </div>
            <LabelInput :disabled="otpInput" :label='lang.walletLabel' @input="value=>walletAddress=value" />

            <div class="warning">
                <div v-if="errorHandler">{{errorHandler}}</div>
            </div>
            <div class="fee">
                <div class="fee-label">{{lang.feeLabel}}</div>
                <div dir='ltr'><span class="fee-value">{{wage}}</span>&nbsp;<span class="fee-tag">{{$coins[relatedCoin].code}}</span></div>
            </div>
            <div v-if="!otpInput" class="modal-text">{{lang.bottomText}}</div>
            <button class="modal-submit-btn" v-if="!otpInput" @click="withdraw()" :class="{disabled:disabled}" :disabled="disabled">
               {{lang.sendCodeBtn}}
            </button>
            <div class="modal-text" v-if="otpInput">{{lang.enterCodeText}}</div>
            <VerifyCodeInput v-if="otpInput" @change="e=>code=e" @complete="submitWithdrawRequest()" :num="6" />
            <div class="email-btn" v-if="otpInput &&  counter" :class="{disabled:disabled}">
                {{Math.floor(countDown/60)}}:{{countDown%60}}
            </div>
            

            <button v-if="otpInput && countDown===0 && counter" class="modal-submit-btn" @click.prevent="send()">
                {{lang.resend}}
            </button>
            <button class="modal-submit-btn" v-if="countDown>0 && otpInput " 
                :class="{disabled:disabled || !otpInput || countDown==0}"
                 @click="submitWithdrawRequest()" :disabled="disabled || !otpInput || countDown==0">{{lang.confirmBtn}} </button>
        </template>
    </template>

</ModalCard>
</template>

<script>
import DropDown from '../Tools/DropDown'

import ModalCard from './ModalCard'
export default {
    name: 'AddTicket',
    components: {
        ModalCard,
        DropDown
    },
    props: ['relatedCoin', 'exchange', 'credit'],
    data() {
        return {
            lang: this.$languageDict().wallets.modals.coinWithdraw,
            amount: '',
            walletAddress: '',
            local: false,
            token: false,
            transaction: {
                amount: "",
                destinationWalletAddress: "",
                relatedCoin: "",
                tokenType: "",
                code: "",
                walletType: (this.exchange ? 'EXCHANGE' : 'P2P')
            },
            towStepAuthenticationType: "",
            otpInput: false,
            counter: false,
            timeOut: '',
            countDown: 120,
            code: '',
            selected_net: "",
            txIdIsNeeded: false,
            drop: false,
            enable: false,
            nets: [],
            coins: [],
            wage: 0,
            cryptoNetworkIndex: 0,
            coinData: {},
            min: '',
            max: '',

        }
    },
    computed: {
        netsList() {
            if (this.nets)
                return this.nets.map(net => {
                    return {
                        label: net.tokenType,
                        value: net.tokenType
                    }
                })
            return false
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min))
            // ( !this.enable )
            // (!this.selected_net)
        },
        errorHandler() {
            if ((this.$S2N(this.amount) > this.$S2N(this.credit)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.min)))
                return 'مقدار برداشت باید حداقل' + this.$coins[this.relatedCoin].code + ' ' + this.min + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.max)))
                return 'مقدار برداشت باید حداکثر ' + this.$coins[this.relatedCoin].code + ' ' + this.max + ' باشد '
            else
                return ''

        },
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        selectNet() {
            console.log(this.nets)
            this.selected_net = this.nets[this.cryptoNetworkIndex].tokenType
            this.transaction.tokenType = this.selected_net
            this.txIdIsNeeded = this.nets[this.cryptoNetworkIndex].txIdIsNeeded
            this.wage = this.nets[this.cryptoNetworkIndex].withdrawalFee
            this.max = this.nets[this.cryptoNetworkIndex].maxWithdraw
            this.min = this.nets[this.cryptoNetworkIndex].minWithdraw
            this.enable = this.nets[this.cryptoNetworkIndex].withdrawEnable || true
            this.drop = false
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            this.coinData = this.coins.find(coin => coin.coin == this.relatedCoin)
            this.nets = this.coinData.tokenTypeDetails
        },
        async send() {
            this.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        async withdraw() {
            if (!this.disable && !this.state.loading) {
                this.state.loading = true
                this.transaction.amount = this.$S2N(this.amount),
                    this.transaction.destinationWalletAddress = this.walletAddress,
                    this.transaction.relatedCoin = this.relatedCoin
                if ('TETHER' === this.relatedCoin) {
                    this.transaction.tokenType = this.token ? 'ERC20' : 'TRC20'
                }
                const res = await this.$axios.post("/users/send-code")
                this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                this.otpInput = true
                if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
                    this.counter = true
                    this.countDownTimer()
                }
            }
        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.code = this.code
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.transaction)
            if (res) {
                this.$emit('modalToggle', '')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged

            }
        },
    },
    mounted() {
        this.getData()
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
}
</script>

<style scoped>
button {
    width: 100%;
}

.crypto-network {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}



.disabled {
    opacity: 0.6;
}

.warning {
    color: #FF2D78;
    font-size: clamp(11px, 1.1vw, 12px);
    font-family: 'YekanLight';
    display: flex;
    align-items: flex-start;
    column-gap: 7px;
}

.fee-label {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #E0E0E0;
}

.fee-value {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #FFFFFF;
}
.fee{
    display: flex;
    justify-content: space-between;
}
.fee-tag {
    color: #E0E0E0;
    font-size: clamp(12px, 1.3vw, 14px);

}
.credit{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.credit-label {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #F2F2F2;

}

.credit-tag {
    color: #CB07C3;
    font-size: clamp(12px, 1.3vw, 14px);
}

.credit-value {
    font-size: clamp(12px, 1.3vw, 14px);
    font-weight: bold;
    color: #CB07C3;
    text-decoration-line: underline;

}
</style>
