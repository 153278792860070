<template>
<ModalCard @close="$emit('modalToggle','')">
     <template #title>
       {{lang.modalTitle}}
    </template>
     <template #body>
             <p class="modal-text">{{lang.topText}}</p>
         <div class="details">
             <div class='detail'>
                 <span class="detail-label">{{lang.request}}</span>
                 <span class="detail-value" :class="{buy:order.tradeType=='BUY' ,sell:order.tradeType=='SELL'}">{{order.tradeType+' '+order.orderType}}</span>
             </div>  
             <div class='detail'>
                 <span class="detail-label">{{lang.amount}}</span>
                 <span class="detail-value" dir="ltr">{{order.amount}}&nbsp;<span class="detail-tag">{{$coins[order.coin].code}}</span></span>
             </div>  
             <div class='detail'>
                 <span class="detail-label">{{lang.price}}</span>
                 <span class="detail-value">{{ $toLocal(order.unitPrice,$decimal[order.baseCoin])}}&nbsp;<span class="detail-tag">{{$coins[order.baseCoin].code}}</span></span>
             </div>  
              <div class='detail'>
                 <span class="detail-label">{{lang.total}}</span>
                 <span class="detail-value">{{$toLocal(sum,$decimal[order.baseCoin])}}&nbsp;<span class="detail-tag">{{$coins[order.baseCoin].code}}</span></span>
             </div>    
             <div class='detail' v-if="order.date">
                 <span class="detail-label">{{lanng.date}}</span>
                 <span class="detail-value">{{$G2J(order.date)}}</span>
             </div>   
         </div>
         <button class="modal-submit-btn" @click="$emit('confirm')" >{{lang.confirmBtn}} </button>
     </template> 
   

  </ModalCard>
    
</template>

<script>
import ModalCard from './ModalCard'
export default {
  name: 'AddTicket',
  components:{
      ModalCard,
  },
  props:['order'],
  computed:{
      sum(){
          return this.order.unitPrice * this.order.amount
      }
  },
  data(){
    return{
        lang:this.$languageDict().trade.confirmModal,
        dict:{
            "MARKET":'بازار',
            "LIMIT":'حد',
            "EXCHANGE":'صرافی',
            "BUY":'خرید',
            "SELL":'فروش'
        }
      
    }
  }
}
</script>
<style scoped>
.text{
    font-size: clamp(13px,1.4vw,15px);
    color:var(--modal-text)

}
.buy{
    color: var( --modal-orderType-buy);
}
.sell{
    color: #DD1B60;
}
.detail-label {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #E0E0E0;
}

.detail-value {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #FFFFFF;
}
.detail{
    display: flex;
    justify-content: space-between;
}
.detail-tag {
    color: #E0E0E0;
    font-size: clamp(12px, 1.3vw, 14px);

}
.details {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
</style>