<template>
<div>
    <div class="history-table-title">
        {{lang.exchange}}
    </div>
    <div class="filters">
        <RangeCalendar @input="value=>{startDate=value[0];endDate=value[1]}" />
        <DropFilter class="f-3" @choose="(value)=>{coin=value.value}" :items="coinsList" />

    </div>
    <div class="history-table-container">
        <perfect-scrollbar @ps-y-reach-end="getOrders()" ref="scroll">
            <table class="history-table">
                <tr class="history-table-header">
                    <td class="resp-1"> {{lang.tableHeaders.time}} </td>
                    <td>{{lang.tableHeaders.coin}}</td>
                    <td class="resp-3">{{lang.tableHeaders.type}}</td>
                    <td class="resp-3">{{lang.tableHeaders.price}}</td>
                    <td> {{lang.tableHeaders.volume}} <span class="tag" v-if="coin.value">({{coin}})</span></td>
                    <td class="resp-2">{{lang.tableHeaders.total}} </td>
                    <td class="resp-1">{{lang.tableHeaders.status}}</td>
                </tr>
                <tr class="history-table-row" v-for="(order,index) in orders" :key="index">
                    <!-- تاریخ -->
                    <td class="resp-1">{{$G2J(order.createdAtDateTime)}}</td>
                    <!-- نوع ارز -->
                    <td>
                        <div class="coin-title">
                            <span>{{$coins[coinName(order.marketType)].code}}</span>
                        </div>
                    </td>
                    <!-- نوع سفارش -->
                    <td :class="{'resp-3':true,'green':order.orderType.includes('BUY'),'red':order.orderType.includes('SELL')}">
                        {{lang[order.orderType.includes('BUY')?'BUY':'SELL']}}
                    </td>
                    <!-- قیمت واحد -->
                    <td class="resp-3">{{$toLocal(order.unitPrice,$decimal[baseCoin(order.marketType)])}}<span v-if="!coin" class="tag">{{$coins[baseCoin(order.marketType)].code}}</span></td>
                    <!-- مقدار سفارش -->
                    <td>{{order.amount}} <span class="tag"> {{$coins[coinName(order.marketType)].code}}&nbsp;</span></td>
                    <!-- قیمت کل -->
                    <td class="resp-2">{{$toLocal(order.wholePrice,$decimal[baseCoin(order.marketType)])}}&nbsp;<span class="tag">{{$coins[baseCoin(order.marketType)].code}}</span></td>
                    <!-- وضعیت -->
                    <td class="resp-1">
                        <div :style="{color:statusColor(order.orderStatus)}" class="status">{{lang.statusLabels[order.orderStatus]}}</div>
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>

</div>
</template>

<script>
import RangeCalendar from '../../Tools/RangeCalendar'
import DropFilter from './DropFilter'
import {
    statusColor,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'Trades',
    components: {
        DropFilter,
        RangeCalendar
    },
    watch: {
        query() {
            this.page = 0
            this.lastPage = 1
            this.orders = []
            this.getOrders()
        }
    },

    computed: {
        statusColor,
        coinsList() {
            let coins = Object.entries(this.$coins).filter(coin => coin[1].code && coin[0] != 'TOMAN').map(coin => {
                return {
                    label: coin[1].code,
                    value: coin[0]
                }
            })
            coins.unshift({
                label: 'Coin',
                value: ''
            })
            return coins
        },

        query() {

            let coinsStr = '?size=20'
            if (this.startDate || this.endDate)
                coinsStr += '&startDate=' + this.startDate + 'T00:00:00' + '&endDate=' + this.endDate + 'T23:59:59'

            if (this.coin) {
                coinsStr += '&market=' + this.coin + '_' + 'TOMAN'
                coinsStr += '&market=' + this.coin + '_' + 'TETHER'

            }
            if (this.dealType) {
                coinsStr += '&orderType=' +'EXCHANGE' + '_' + this.dealType
                coinsStr += '&orderType=' + this.dealType
            } else {
                coinsStr += '&orderType=' + 'EXCHANGE' + '_' + 'BUY'
                coinsStr += '&orderType=' + 'EXCHANGE' + '_' + 'SELL'
            }
            return coinsStr
        },
    },
    methods: {
        coinName(market) {
            return market.includes('TOMAN') ? market.replace('_TOMAN', '') : market.replace('_TETHER', '')
        },
        baseCoin(market) {
            return market.includes('TOMAN') ? 'TOMAN' : 'TETHER'
        },

        // async getP2POrders() {
        //     // if (this.page <= this.lastPage) {
        //     this.page++
        //     let [res, ] = await this.$http.get('/orders', {
        //         params: {
        //             market: 'BITCOIN' + '_' + 'TOMAN',
        //             orderType: 'P2P',
        //             size: 20
        //         }
        //     })
        //     if (res.content)
        //         this.P2PDataSet = res.content;

        //     this.loading = false
        // },
        // async getExchangeOrders() {
        //     // if (this.page <= this.lastPage) {
        //     this.page++
        //     let [res, ] = await this.$http.get('/orders', {
        //         params: {
        //             // market: this.tradeFrom + '_' + this.tradeTo,
        //             type: 'EXCHANGE',

        //             size: 20
        //         }
        //     })
        //     if (res.content)
        //         this.exchangeDataSet = res.content;
        //     this.loading = false
        // },

        async getOrders() {
            if (this.page <= this.lastPage) {
                this.page++
                let [res, ] = await this.$http.get(`/orders${this.query+'&page='+this.page}`)
                this.orders = [...this.orders, ...res.content];
                this.lastPage = res.totalPages
            }
            this.loading = false
        }
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getOrders, 5000, 'getOrders')
            this.loop.start()
        } else {
            this.getOrders()
        }

    },
    beforeDestroy() {
        this.loop.stop()
    },
    data() {
        return {
            endDate: '',
            startDate: '',
            lang: this.$languageDict().history,
            coin: '',
            page: 0,
            lastPage: 1,
            orders: [],
          
            is_open: true,
            dealType: '',
            dict: {
                'SELL': 'فروش',
                'BUY': 'خرید'
            },
            coins: [{
                    label: 'همه ارزها',
                    value: ''
                },
                {
                    label: 'بیت کوین',
                    value: 'BTC'
                },
                {
                    label: 'اتریوم کلاسیک',
                    value: 'ETC'
                },
                {
                    label: ' اتریوم',
                    value: 'ETH'
                },
            ],
            marketTypes: [{
                    label: 'بازار',
                    value: 'MARKET'
                },
                {
                    label: 'بازار نوقف',
                    value: 'STOP_MARKET'
                },
                {
                    label: ' حد',
                    value: 'LIMITED'
                },
                {
                    label: 'حد توقف',
                    value: 'STOP_LIMIT'
                },
            ],

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.f-1 {
    z-index: 22 !important;
}

.f-2 {
    z-index: 21 !important;
}

.f-3 {
    z-index: 20 !important;
}

.mar {
    width: 600px;
}

@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
