<template>
<div class="section">
    <div class="section-title">{{lang.sectionTitle}}</div>
    <div class="section-text">{{lang.sectionText}}</div>
    <form class="input-fields">
        <LabelInput :disabled="readOnly" @input="value=>data.firstName=$S2Fa(value)" :value="data.firstName" :label='lang.firstName' />
        <LabelInput :disabled="readOnly" @input="value=>data.lastName=$S2Fa(value)" :value="data.lastName" :label='lang.lastName'  />
        <LabelInput type="nochar" :maxLength="10" @input="value=>data.nationalCode=value" :value='data.nationalCode' :disabled="readOnly" :label='lang.idCardNumbar'  />
        <Calendar :disabled="readOnly" :isBirth="true" @input="value=>data.birthDay=value" :value="data.birthDay" :label='lang.birthDate'  />
        <LabelInput type="nochar" :maxLength="11" @input="value=>data.mobileNumber=value" :value="data.mobileNumber"  :label='lang.cellPhone'  />
        <LabelInput type="nochar" :maxLength="11" @input="value=>data.telephone=value" :value="data.telephone"  :label='lang.wirePhone'  />
        <LabelInput @input="value=>data.address=value" :value="data.address"  :label='lang.address'  />

    </form>

</div>
</template>

<script>
import Calendar from '../../Tools/Calendar'

export default {
    name: 'PersonalFields',
    props: {
        data: Object,
        readOnly: Boolean
    },
    components: {
        Calendar
    },
    data() {
        return {
            lang: this.$languageDict().profile.personalFields,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.input-fields>div {
    flex-basis: 368px !important;

}

.input-fields {
    margin-top: clamp(16px, 2vw, 24px);
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
}

textarea:focus {
    outline: none !important;

}

textarea {
    width: 100%;
    background: var(--label-input-bg);
    color: var(--label-input-text);
    border: 0.3px solid var(--label-input-border);
    border-radius: 20px;
}
</style>
