<template>
<div class="coin-page ">
    <div class="url">
        Home
        <span >
            <inline-svg :src="require('../../../assets/icons/arrowdown.svg')" class="url-arrow" />
            Coins
        </span>
        <span  class="coin-url">
            <inline-svg :src="require('../../../assets/icons/arrowdown.svg')" class=" url-arrow" />
          {{coin.toLowerCase().split('_').join(' ')}}
        </span>
    </div>
    <div>
        <CoinDetails :crypto="crypto"/>
        <PriceStatistics :crypto="crypto"/>
        <CoinDescription/>

    </div>

</div>
</template>

<script>
import PriceStatistics from './PriceStatistics'
import CoinDescription from './CoinDescription'
import CoinDetails from './CoinDetails'
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    
    name: 'Coin',
    components:{
        PriceStatistics,
        CoinDescription,
        CoinDetails


    },
    data(){
        return{
            coin:this.$route.params.crypto,
            crypto:{}
        }
    },
    methods:{
       async getCoin() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: true,
                    market_type:this.coin+"_TOMAN"
                }
            }).then(response => res = response.content)
            if (res) {
                this.crypto = res[0]
            }
        },
    },
    mounted() {
       if (this.state.loop) {
            this.loop1 = new Loop(this.getCoin, 5000, 'getCoin')
            this.loop1.start()
        } else {
            this.getCoin()
        }

    },
    beforeDestroy() {
        this.loop1.stop()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.coin-page{
margin-top: 100px;
padding-bottom: 80px;
width: 85%;
}
.url{
    color: white;
    font-size: clamp(13px,1.3vw,14px);
    display: flex;
    column-gap: 10px;
}
.coin-url{
    color: #E0E0E0;
}
.url-arrow{
    fill: white;
    max-width: 20px;
    transform: rotate(-90deg);

}
</style>
