<template>
<div class="notif-page">
    <h1>{{lang.pageTitle}}</h1>

    <div class="notifications">
        <div v-for="(notification,index) in notifications" :key="index" :class="{'read-notif':notification.read,'open-notification':openedIndex==index}" class="notification" @click="readMessage(notification.id,index)">
            <div class="notification-header">
                <div class="title-read">
                    <div class="unread-circle" :class="{'read-circle':notification.read }" /><span>{{notification.title}}</span>
                </div>
                <div class="date-arrow resp">
                    <span>{{$G2J(notification.createdAtDateTime)}}</span>

                    <inline-svg :class="{'open-arrow':openedIndex==index,'arrow-icon':true,'read-arrow-icon':notification.read}" :src="require('../../assets/icons/arrowdown.svg')" />
                </div>
            </div>
            <div v-if="openedIndex==index" class="content">
                <div>{{notification.content}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Notifications',
    props: {

    },
    data() {
        return {
            lang: this.$languageDict().notifications,
            openedIndex: null,
            notifications: []

        }
    },
    methods: {
        async getMessages() {
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    page: 1,
                    size: 13,
                }
            })
            if (res.message !== 'No Notifications') {
                this.notifications.push(...res.content)
                this.unreadedCount = res.totalElements
            } else {
                this.notifications = []
                this.unreadedCount = 0
            }
        },
        async readMessage(id, index) {
            this.openedIndex = (this.openedIndex == index) ? null : index;
            if (!this.notifications[index].read) {
                this.notifications[index].read = true
                await this.$axios.put(`/users/notifications/read/${id}`)
            }
            this.$forceUpdate()
        },
    },
    mounted() {
        this.getMessages()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
h1 {
    color: #E0E0E0;
    font-size: clamp(25px, 3vw, 36px);
    font-weight: bold;
}
.content{
    padding-bottom: 16px;
}
.notification {
    margin-top: 8px;
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 8px;
    padding: 0px 17px;
    color: #F2F2F2;
    font-size: clamp(14px,1.5vw,16px);
    transition: height 1s;
    cursor: pointer;
}
.notification:hover{
    background: #10183C;
}
.open-notification{
    background: #171B2C;
}
.arrow-icon{
    fill: #FCFCFC;
    width: 20px;
}
.read-arrow-icon{
    fill: #BDBDBD;
}
.open-arrow{
    transform: rotate(-180deg);
}
.notification-header {
    display: flex;
    white-space: nowrap;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.unread-circle {
    background: #CB07C3;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.read-circle {
    background: #BDBDBD;

}

.read-notif {
    background: #171B2C !important;
    color: #BDBDBD !important;
}

.title-read {
    display: flex;
    column-gap: clamp(10px,1.3vw,16px);
    align-items: center;
}

.date-arrow {
    display: flex;
    column-gap: clamp(13px,1.4vw,19px);
    align-items: center;
}
</style>
