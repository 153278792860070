<template>
<div class="coin-description">
    <h1>what is {{coin.toLowerCase().split('_').join(' ')}}?</h1>
    <p>{{coin.toLowerCase().split('_').join(' ')}} is an open-source software that since 2009 has enabled the exchange of an entirely new form of money (BTC) over the internet. Created by an unknown individual or group operating under the pseudonym Satoshi Nakamoto, Bitcoin sought to offer a “peer-to-peer digital cash system” that removed middlemen from online commerce through the clever use of public key cryptography and peer-to-peer networking. The result was that, for the first time, money could be introduced to an economy on a schedule immune to the influence of any central bank or operator. As set out in the rules of the software, only 21 million bitcoins, divisible into many more smaller units, can ever be created. By creating true digital scarcity, Bitcoin would go on to entice a new generation of traders who now see BTC as a viable alternative to gold and traditional monies.</p>
</div>
</template>

<script>

export default {
    name: 'Coin',
    data(){
      return{
        coin:this.$route.params.crypto
      }
    },
    

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
h1{
    color: white;
    margin-top: 60px;
    font-size: clamp(22px,2.3vw,28px);
    text-transform: capitalize;

}
p{
    color: white;
    font-size: clamp(15px,1.6vw,18px);
    text-transform: capitalize;

}
.coin-description{

}
</style>
