<template>
<div class="trade-comp">
    <div class="coin-title-icon">
        <img class="coin-icon" :src="require('../../../../public/coins/'+tradeFrom+'.png')" />
        <div>
            <div class="coin-title-change">
                <span class="title">{{$coinUnit[tradeFrom]}}/{{lang[tradeTo]}}</span>
                <div class="change" dir="ltr" :class="{'green':crypto.last24HourChange>0,'red':crypto.last24HourChange<0}">
                    <span v-if="crypto.last24HourChange>0">+</span>
                    <span v-if="crypto.last24HourChange<0">-</span>
                    {{$toLocal(Math.abs(crypto.last24HourChange),2)}}%
                </div>
            </div>
            <div :class="{'green':crypto.last24HourChange>0,'red':crypto.last24HourChange<0}">{{$toLocal(crypto.lastPrice,tradeTo=='TOMAN'?0:2)}}</div>
        </div>
    </div>
    <div class="trade-card">

        <div class="dealType-switch">
            <div :class="{'active sell':!isBuy}" @click="isBuy=false">{{lang.SELL}}</div>
            <div :class="{'active buy':isBuy}" @click="isBuy=true">{{lang.BUY}}</div>
        </div>
        <div class="ordertype-toggler" @click="orderType=(orderType=='LIMIT')?'MARKET':'LIMIT'">
            <inline-svg class="arrow-icon" :src="require('../../../assets/icons/arrowdown.svg')" />
            <div>{{orderType}}</div>
            <inline-svg class="arrow-icon" :src="require('../../../assets/icons/arrowdown.svg')" />
        </div>
        <!-- موجودی     -->
        <div class="credit">
            <div class="credit-label">{{lang.avblLabel}}</div>
            <div><span class="credit-value"> {{credit}}</span>&nbsp;<span class="credit-tag">{{(isBuy)?lang[tradeTo]:$coinUnit[tradeFrom]}}</span></div>
        </div>
        <!-- قیمت واحد -->
        <LabelInput type='number' :unit="lang[tradeTo]" @input="value=>unitPrice=value" :label="lang.unitPrice" :value="$toLocal(unitPrice,0)" :disabled="orderType=='MARKET'" />
        <!-- <div class="subtext" v-if=" orderType=='LIMIT' || orderType=='MARKET'">
            <div v-if="isBuy" class="subtext-buy"> پایین ترین پیشنهاد فروش{{$toLocal(shopInfo.minSell,$decimal[tradeTo])}} {{$coinLabel[tradeTo]}}</div>
            <div v-else class="subtext-sell"> بالاترین پیشنهاد خرید {{$toLocal(shopInfo.maxBuy,$decimal[tradeTo])}} {{$coinLabel[tradeTo]}}</div>
        </div> -->

        <!-- مقدار کوین -->
        <LabelInput :value="$toLocal(amount,$decimal[tradeFrom])" @input="value=>amount=value" :label="lang.amount" :unit="$coinUnit[tradeFrom]" type="number" />

        <!-- اسلایدر قیمت -->
        <div class="slider-input">
            <input v-model="amountPercent" class="range-input" type='range' />
            <div @click="amountPercent=(i-1)*25" v-for="i in 5" :key="i">
                {{(i-1)*25}}%
            </div>
            <div class="thumb-container">
                <div :style="{left:amountPercent+'%'}" class="thumb">{{amountPercent}}%</div>
            </div>
        </div>

        <Calendar v-if="orderType=='LIMIT'" :label="lang.expireLabel" />
        <p class="error">{{errorTxt}}</p>

        <LabelInput :disabled="true" :value="sum" :unit="lang[tradeTo]" :label="lang.totalLabel" />
        <div class="fee-row">
            <span>{{lang.feeLabel}}</span>
            <span>{{fee}}&nbsp;%</span>
        </div>
        <button :class="{'buy-btn':isBuy,'sell-btn':!isBuy,disabled:disabled}" @click="showModal">{{(isBuy?lang.BUY:lang.SELL)}}&nbsp;{{tradeFrom}}</button>
        <TradeConfirm @confirm="accept()" v-if="modalShow" @modalToggle="value=>modalShow=value" :order="order" />

    </div>
</div>
</template>

<script>
import Calendar from '../../Tools/Calendar'
import TradeConfirm from '../../Modals/TradeConfirm'
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'TradeComp',
    props: ['crypto', 'shopInfo'],
    computed: {
        tradeTo,
        tradeFrom,

        sum() {
            return this.$toLocal(this.$S2N(this.amount) * this.$S2N(this.unitPrice), this.$decimal[this.tradeTo])
        },

        credit() {
            if (this.isBuy)
                return this.wallets[this.tradeTo] ? this.$toLocal(this.wallets[this.tradeTo].credit, this.$decimal[this.tradeTo]) : 0
            else
                return this.wallets[this.tradeFrom] ? this.$toLocal(this.wallets[this.tradeFrom].credit, this.$decimal[this.tradeFrom]) : 0
        },

        fee() {
            return this.$S2N(this.state.userInfo.fee)
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.unitPrice ||
                ((this.isBuy ? this.$S2N(this.sum) : this.$S2N(this.amount)) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmount)) ||
                (this.$S2N(this.amount) < this.$S2N(this.crypto.minAmount))
        },
        errorTxt() {
            if ((this.isBuy ? this.$S2N(this.sum) : this.$S2N(this.amount)) > this.$S2N(this.credit))
                return this.langErrors.creditError
            else if ((this.$S2N(this.amount) < this.$S2N(this.crypto.minAmount)))
                return this.langErrors.tradeMinAmount[0] + this.$coins[this.tradeFrom].code + ' ' + this.crypto.minAmount + this.langErrors.tradeMinAmount[1]
            else if ((this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmount)))
                return this.langErrors.tradeMaxAmount[0] + this.$coins[this.tradeFrom].code + ' ' + this.crypto.maxAmount + this.langErrors.tradeMaxAmount[1]
            else if (!this.percentSliderActive && this.amountPercent > 0)
                return this.langErrors.sliderError
            else if (!this.amount)
                return this.langErrors.amountError
            else if (!this.unitPrice && this.orderType.includes('LIMIT'))
                return this.langErrors.unitPriceError
            else
                return ''

        },
        percentSliderActive() {
            return !(this.orderType == 'LIMIT' && this.isBuy && !this.unitPrice)
        },

        coinsOpts() {
            return this.cryptos.map(coin => {
                return {
                    label: this.$coinLabel[coin.relatedCoin] + `( ${coin.relatedCoin})`,
                    value: this.$coin2Snake[coin.relatedCoin]
                }
            })
        },

    },
    components: {
        Calendar,
        TradeConfirm
    },
    watch: {

        'amountPercent': function () {
            if (this.orderType.includes('MARKET') && this.isBuy)
                this.getAmountFromServer()
            else if (this.percentSliderActive) {
                this.localCalculate()
            }
        },
        'amount': function () {
            if (this.orderType.includes('MARKET')) {
                this.getPriceFromServer()
                console.log(this.unitPrice)
            }
        },

    },
    data() {
        return {
            lang: this.$languageDict().trade.tradeComp,
            langErrors: this.$languageDict().errors,

            amountPercent: 0,
            depsoit: 0.0067,
            isBuy: true,
            modalShow: false,
            amount: 0,
            unitPrice: 0,
            coinsDetails: [],

            orderType: "LIMIT",
            networkOpts: [],
            order: [],

            types: [

                "MARKET",
                "LIMIT",
            ],
            wallets: []

        }
    },
    methods: {
        async getWallet() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                this.wallets = res
            }
        },
        showModal() {
            if (!this.disabled) {
                this.order = {
                    amount: this.$S2N(this.amount),
                    unitPrice: this.$S2N(this.unitPrice),
                    baseCoin: this.tradeTo,
                    coin: this.tradeFrom,
                    tradeType: this.isBuy ? 'BUY' : 'SELL',
                    orderType: this.orderType
                }
                if (this.date)
                    this.order.date = this.date
                console.log(this.isBuy)
                this.modalShow = true
                // this.modalType = this.isBuy ? 'BUY' : 'SELL'

            }

        },

        setValue(index) {
            this.amountPercent = this.steps[index].value
        },

        inputRange() {
            this.$emit('rangeValue', this.val)
        },
        // async getCoinsDetails() {
        //     let coin
        //     this.state.loading = true
        //     const res = await this.$axios.get('/coins')
        //     this.coinsDetails = res.content
        //         coin = this.coinsDetails.find(crypto => crypto.coin == this.tradeFrom)
        //         this.maxAmount = coin.maxAmount
        //         this.minAmount = coin.minAmount
        // },
        async getPriceFromServer() {
            let amount = this.$S2N(this.amount);
            if (amount) {
                const res = await this.$axios.get('/orders/market-buy-sell-whole', {
                    params: {
                        amount: amount,
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: `MARKET_${this.isBuy?'BUY':'SELL'}`
                    }
                })
                let price = res.baseDTO.wholePrice;
                this.totalPrice = this.$toLocal(price.toFixed(0));
                this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount).toFixed(0));
                console.log(this.unitPrice)
                this.value = this.$S2N(this.totalPrice) / this.cash
            }
        },
        localCalculate() {
            let tempAmount = (this.amountPercent / 100) * this.$S2N(this.credit)
            if (this.isBuy)
                this.amount = tempAmount / this.$S2N(this.unitPrice)

            else
                this.amount = tempAmount
        },
        async getAmountFromServer() {
            let e = this.amountPercent / 100 * this.$S2N(this.credit)
            if (e) {
                this.getAmount = true
                const res = await this.$axios('/orders/amount-via-price', {
                    params: {
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY',
                        amount: e
                    }
                }).catch(() => {})
                this.getAmount = false
                if (res) {
                    this.amount = this.$toLocal(res.baseDTO.wholePrice, this.$decimal[this.tradeFrom])
                    // this.calculate()
                }
            }
        },
        calculate() {
            if (this.orderType.includes('LIMIT') && this.unitPrice && this.amount) {
                this.localCalculate()
            } else if (this.orderType.includes('MARKET')) {
                this.serverCalculate()
            }
        },
        async serverCalculate() {
            this.calculating = true
            const a = this.amount
            const temp = ++this.count
            await this.$sleep(500)

            if (a) {
                while (this.alive && a && a === this.amount && temp === this.count) {
                    try {
                        await this.getPriceFromServer()
                        this.calculating = false
                        await this.$sleep(5000)
                    } catch (e) {
                        this.calculating = false
                        break
                    }
                }
            } else {
                this.calculating = false
            }

        },

        filterOrders(filter) {
            return this.orders.filter(order => {
                if (order.tradeType == filter)
                    return order
            })
        },

        isLoaded() {
            return this.wallets && this.crypto && this.tableContents && this.shopInfo.minSell
        },
        async accept() {
            this.loading = true;
            let orderDetail;
            let marketType = this.tradeFrom + '_' + this.tradeTo
            if (this.orderType == 'LIMIT') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `LIMITED_${this.isBuy ? 'BUY' : 'SELL'}`,
                    unitPrice: this.$S2N(this.unitPrice),
                    wholePrice: this.$S2N(this.sum),
                }
                if (this.date) {
                    orderDetail.expiresAt = this.date || null
                }
            } else if (this.orderType == 'MARKET') {
                orderDetail = {
                    marketType: marketType,
                    amount: this.$S2N(this.amount),
                    orderType: `MARKET_${this.isBuy ? 'BUY' : 'SELL'}`,
                    wholePrice: this.$S2N(this.sum),
                    unitPrice: this.$S2N(this.unitPrice),

                }

            }
            try {
                await this.$axios.post(`/orders/${this.isBuy ? 'buy' : 'sell'}`, orderDetail)
                this.loading = false
                this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
            } catch (error) {
                this.loading = false
                if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                    await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
                }
            }
            this.modalShow = false
        },
    },
    mounted() {
        this.getWallet()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.trade-comp{
    flex-basis: 300px;
}
.coin-title-icon {
    display: flex;
    column-gap: 12px;
    align-items: center;

    >div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .green {
        color: #28F260 !important;
    }

    .red {
        color: #82112B !important;
    }

    .coin-title-change {
        display: flex;
        justify-content: space-between;

        .title {
            color: #FFFFFF;
            font-size: clamp(16px, 1.8vw, 20px);
            font-weight: bold;

        }

        .change {
            font-size: clamp(14px, 1.6vw, 18px);
            font-weight: bold;
            color: #FFFFFF;
        }

    }

    .price {
        font-size: clamp(14px, 1.6vw, 18px);
        color: #FFFFFF;

    }

}

.coin-icon {
    width: clamp(28px, 3.5vw, 48px);
    height: clamp(28px, 3.5vw, 48px);

}

.ordertype-toggler {
    padding: 0px 16px;
    background: #060D35;
    color: #F2F2F2;
    border-radius: 4px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .arrow-icon:first-child {
        width: 13px;
        transform: rotate(90deg);
        fill: #FCFCFC;
    }

    .arrow-icon:last-child {
        width: 13px;
        transform: rotate(-90deg);
        fill: #FCFCFC;

    }
}

.credit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.credit-label {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #F2F2F2;

}

.credit-tag {
    color: #CB07C3;
    font-size: clamp(12px, 1.3vw, 14px);
}

.credit-value {
    font-size: clamp(12px, 1.3vw, 14px);
    font-weight: bold;
    color: #CB07C3;
    text-decoration-line: underline;

}

.disabled {
    opacity: 0.7;
}

.dealType-switch {
    display: flex;
    padding: 6px 8px;
    color: #828282;
    background: #060D35;
    border-radius: 8px;
    font-size: clamp(14px, 1.5vw, 16px);
    margin-bottom: 24px;

    div {
        flex-grow: 1;
        padding: 5px;
        text-align: center;
        cursor: pointer;

    }

    .buy {
        color: #17B471;
    }

    .sell {
        color: #E30037;
    }

    .active {
        font-weight: bold;
        background: #232555;
        border-radius: 4px;
    }
}

.coin-type {
    z-index: 21 !important;
    min-width: 150px !important;

}

.error {
    color: red;
    font-size: clamp(12px, 1.3vw, 14px);
}

.trade-type {
    z-index: 22 !important;
}

.base-coin {
    z-index: 20 !important;
    flex-basis: 150px !important;

}

.trade-card {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 12px;
    padding: clamp(9px,1.8vw,24px);
    flex-grow: 3;
    // max-width: 430px;
    flex-basis: 350px;
}

.fee-row {
    display: flex;
    margin-top: 8px;
    align-items: center;
    justify-content: space-between;
    font-size: clamp(12px, 1.3vw, 14px);
    color: #F2F2F2;
}

.slider-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    background: #060D35;
    border-radius: 8px;
    font-size: clamp(12px, 1.3vw, 14px);
    color: #BDBDBD;
    height: 29px;
    padding: 0px 5px;
    position: relative;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    height: 25px !important;
    min-width: clamp(40px, 5vw, 60px) !important;

}

.thumb-container {
    width: 90%;
    position: absolute;
    height: 100%;
    margin-left: auto;
}

.thumb-container>.thumb {
    background: #EFF7FF;
    box-shadow: -5px 5px 10px rgba(114, 114, 114, 0.2), 5px -5px 10px rgba(114, 114, 114, 0.2), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(247, 247, 247, 0.5);
    border-radius: 21px;
    position: relative;
    border-radius: 21px;
    max-width: clamp(36px, 5vw, 50px);
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, 2px);
    margin-left: -12px;

}

input[type="range"] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    padding: 0;
    opacity: 0;
    height: 100%;
    z-index: 1;
}

.cost-sum-label {
    font-size: clamp(13px, 1.4vw, 14px);
}

.price-basecoin {
    display: flex;
    align-items: center;
    row-gap: 16px;
    column-gap: 8px;
    flex-wrap: wrap;
}

.inline-input {
    flex-grow: 2;
}

.subtext {
    font-family: 'YekanLight';
    font-size: clamp(10px, 1.1vw, 12px);
}

.subtext-sell {
    color: var(--P2P-TradeComp-sell-subtext);
}

.subtext-buy {
    color: var(--P2P-TradeComp-buy-subtext);
}

.sell-btn {
    background: #82112B;
    border-radius: 8px;
    padding: 8px;
    color: #FCFCFC;
}

.buy-btn {
    background: #02A830;
    border-radius: 8px;
    padding: 8px;
    color: #FCFCFC;

}

@media(max-width:670px) {
    .trade-card {
        max-width: initial;
    }
}
</style>
