<template>
<div class="table-section">
    <div class="table-title">
        <div class="table-filter">
            <div :class="{active:isActive}" @click="isActive=true">{{lang.openOrders}}</div>
            <div :class="{active:!isActive}" @click="isActive=false">{{lang.orderHistory}}</div>
        </div>
    </div>

    <!-- <Table v-if="ordersType=='ActiveOrders'"   :dataSet="dataSet.filter(order=>order.orderStatus === 'IS_OPEN')"
            :columns="[headers.createdAtDateTime,headers.tradeType,headers.unitPrice,{'label':'مقدار('+tradeFrom+')','name':'amount'},headers.totalPrice,headers.commission,headers.fill,headers.action] "/> -->
    <div class="table-container">

        <table>
            <tr class="table-header">
                <td  class="resp-1">{{lang.tableHeaders.date}}</td>
                <td>{{lang.tableHeaders.type}}</td>
                <td>{{lang.tableHeaders.price}}</td>
                <td >{{lang.tableHeaders.amount}}</td>
                <td class="resp-2">{{lang.tableHeaders.total}}</td>
                <td class="resp-1" v-if="isActive"> {{lang.tableHeaders.filled}}</td>
                <td v-if="isActive"> {{lang.tableHeaders.action}} </td>
                <td class="resp-3" v-if="!isActive"> {{lang.tableHeaders.status}} </td>
            </tr>
            <tr class="table-row" v-for="(order,index) in dataSet.filter(order=> isActive==(order.orderStatus==='IS_OPEN') )" :key='index'>
                <td class="resp-1">{{$G2J(order.createdAtDateTime)}}</td>
                <td :class="{red:order.orderType.includes('SELL'),green:order.orderType.includes('BUY')}">{{order.orderType.includes('BUY')?lang.BUY:lang.SELL}}/{{tradeType(order.orderType)}}</td>
                <td>{{$toLocal(order.unitPrice,0)}}</td>
                <td>{{order.amount}}</td>
                <td class="resp-2">{{$toLocal(order.wholePrice,0)}}</td>
                <td class="resp-1" v-if="isActive">{{order.executedPercent}}</td>
                <td v-if="isActive" @click="cancelOrderModal(order)" class="cancel-btn">Cancel</td>
                <td class="resp-3" v-if="!isActive" :class="{red:order.orderStatus=='CANCELLED_BY_USER'
                ,green:order.orderStatus=='FINISHED_WITHOUT_TRANSACTION'||
                order.orderStatus=='COMPLETED'}">{{conditions[order.orderStatus]}}</td>
            </tr>
        </table>

    </div>

    <CancelModal v-if="modalType=='CANCEL'" @modalToggle="value=>modalType=value" @confirm="removeorder()" />
</div>
</template>

<script>
import CancelModal from '../../Modals/CancelModal'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";

export default {
    name: 'HistoryOfOrders',
    components: {
        CancelModal
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    data() {
        return {
            lang: this.$languageDict().trade.ordersHistory,
            //  ordersType:'ActiveOrders',
            dataSet: [],
            isActive: true,
            removeid: '',
            modalType: '',
            conditions: {
                'FINISHED_WITHOUT_TRANSACTION': 'موفق',
                'COMPLETED': 'موفق',
                'CANCELLED_BY_USER': 'لغو شده'
            }

        }
    },
    methods: {
        async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalType = ''
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getLastOrders()
                }
            }
        },
        cancelOrderModal(e) {
            this.loop.stop()
            this.modalType = 'CANCEL'
            this.removeid = e.id
        },
        tradeType(type) {
            return type.includes('EXCHANGE') ? 'EXCHANGE' : type.includes('MARKET') ? 'MARKET' : 'LIMIT'
        },
        async getLastOrders() {
            // if (this.page <= this.lastPage) {
            this.page++
            let res = await this.$axios.get('/orders', {
                params: {
                    market: this.tradeFrom + '_' + this.tradeTo,
                    size: 20
                }
            })
            if (res.content)
                this.dataSet = res.content;
            this.loading = false
        }
        // },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getLastOrders, 5000, 'lastOrder')
            this.loop.start()
        } else {
            this.getLastOrders()
        }

    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style lang="scss" scoped>
.table-title {
    background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
    border-radius: 8px 8px 0px 0px;
    padding: 8px;
    font-weight: bold;
    font-size: clamp(16px, 1.8vw, 20px);
    color: white;
}
.table-container{
    padding: 16px;
    background: #18223D;

}
.table-filter {
    display: flex;
    column-gap: 16px;

    div {
        padding: 8px;
        font-size: clamp(15px, 1.6vw, 18px);
        color: #BDBDBD;
        cursor: pointer;
    }

    .active {
        font-weight: bold;
        color: white !important;
        background: #181D2E;
        border-radius: 8px;
    }
}

.table-section {
    flex-grow: 1;
    flex-basis: 300px;
}

.cancel-btn {
    color: red;
    cursor: pointer;

}

table {
    width: 100%;
}

tr {
    text-align: center;

}
td{
    padding: 9px 0px;
}
td:last-child{
    text-align: right ;
}
td:first-child{
    text-align: left ;
}

.red {
    color: #CA2F4B
}

.green {
    color: #03C70B;
}

.table-header {
    font-size: clamp(12px, 1.3vw, 13px);
    color: #BDBDBD;

}

.table-row {
    font-size: clamp(12px, 1.3vw, 14px);
    color: #FCFCFC;

}
@media(max-width:1200px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:900px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-3 {
        display: none;
    }
}
</style>
