<template>
<div class="support">
    <div class="page-header">
        <div class="page-title">{{lang.pageTitle}}</div>
        <button class="add-new" @click="modalType='AddTicket'">{{lang.createBtn}}</button>
    </div>
    <div class="tickets">
        <div class="ticket" v-for="(ticket,index) in tickets" :key="index">
            <div v-if="activeId==ticket.id || !activeId">
                <div class="headbar" @click="showMessage(ticket.id)">
                    <div class="subject">
                        <div class="circle" />
                        <span>{{ticket.subject}}</span>
                    </div>
                    <div class="date">
                        {{$G2J(ticket.createdAtDateTime)}}
                        <inline-svg :src="require('../../assets/icons/arrowdown.svg')" class="arrow-icon" />
                    </div>
                </div>
                <div class="chat-section" v-if="activeId==ticket.id">
                    <div class="messages">
                        <div class="message" v-for="(message,index) in messages" :key="index" :class="{'admin-message':message.admin ,'user-message':message.customer}">
                            <div class="message-text">
                                <img v-if="message.customer" src="../../assets/icons/send-icon.svg" />
                                <img v-if="message.admin" src="../../assets/icons/reply-icon.svg" />
                                <span>{{message.text}}</span>
                            </div>
                            <!-- <div class="message-date">{{message.createdAtDateTime}}</div> -->
                        </div>
                    </div>
                    <div class="new-message-input">
                        <LabelInput @input="value=>newMessage=value" :placeholder="lang.replyPlaceHolder" />
                        <img class="send-icon" src="../../assets/icons/send-icon.svg" @click="sendMessage()" />

                    </div>

                </div>
            </div>

        </div>
    </div>
    <AddTicket @modalToggle="(value)=>modalType=value"  @addTicket="data=>handleTicket(data)" v-if="modalType=='AddTicket'" />

</div>
</template>

<script>
import AddTicket from '../Modals/AddTicket'

export default {
    name: 'Support',
    props: {},
      components: {
        AddTicket,
    },
    data() {
        return {
            newMessage: '',
            lang: this.$languageDict().support,
            content: [{}],
            messages: [],
            activeId: '',
            reload: false,
            data: {
                type: '',
                subject: '',
                text: '',
            },
            modalType: '',
            activeIndex: null,
            tickets: []

        }
    },
    methods: {
        handleTicket(formData) {

            if (this.activeId) {
                this.sendMessage(formData)

            } else {
                this.addTicket(formData)

            }
            console.log(formData)
        },

        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/tickets')
            this.tickets = res.content
        },
        async showMessage(id) {
            if (this.activeId == id) {
                this.activeId = null
            } else {
                this.state.loading = true
                this.activeId = id
                const res = await this.$axios.get('/tickets/message/' + this.activeId)
                this.messages = res.pageDTO.content.reverse()
                this.reload = !this.reload
                this.state.loading = false
            }

        },
        async showMessageAgain(index) {
            this.state.loading = true
            const res = await this.$axios.get('/tickets/message/' + index)
            this.messages = res.pageDTO.content.reverse()
            this.reload = !this.reload
            this.state.loading = false
        },

        async sendMessage() {
            this.state.loading = true
            let formData = new FormData
            formData.append('text', this.newMessage)
            const res = await this.$axios.post('/tickets/message/' + this.activeId, formData)
            this.messages.push(res.baseDTO)
            this.showMessageAgain(this.activeId)
        },
        async addTicket(formData) {
            this.state.loading = true
            await this.$axios.post('/tickets', formData)
            // this.$push({
            //     name: 'SupportMassage',
            //     params: {
            //         id: res.baseDTO.id
            //     }
            // })

        }

    },
    mounted() {
        this.getData()
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.new-message-input {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 16px;
}
.send-icon{
    position: absolute;
    right: 13px;
}
.arrow-icon {
    fill: #fcfcfc;
    cursor: pointer;
    width: 20px;

}

.messages {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.add-new {
    background: linear-gradient(90deg, #1A97BF 2.72%, #721AC9 28.48%, #AB0AE3 62.88%, #C102C5 78.84%, #BB1396 100%);
    border-radius: 8px;
    height: 35px;
    padding: 0px 15px;
    font-size: clamp(14px, 1.5vw, 16px);
    color: #FCFCFC;
}

.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    row-gap: 20px;
}

.message-date {
    color: #E0E0E0;
    font-size: clamp(11px, 1.1vw, 12px);
}

.tickets {
    margin-top: 24px;
}

.page-title {
    font-size: clamp(24px, 3vw, 36px);
    color: #E0E0E0;
    font-weight: bold;
}

.subject {
    display: flex;
    column-gap: 16px;
    align-items: center;
}

.date {
    display: flex;
    column-gap: 19px;
    align-items: center;
}

.headbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    color: #F2F2F2;
    font-size: clamp(14px, 1.5vw, 16px);
}

.chat-section {
    padding: 8px 0px 16px 0px;
}

.ticket {
    margin-top: 8px;
    padding: 0px 16px;
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 8px;
    cursor: pointer;
}

.open-ticket {
    background: linear-gradient(90.07deg, rgba(47, 43, 73, 0.95) 1.28%, rgba(75, 71, 97, 0.84) 99.22%);
}

.user-message {
    color: #E0E0E0;

}

.message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: clamp(12px, 1.3vw, 14px);

}

.message-text {
    display: flex;
    align-items: center;
    column-gap: 17px;
    font-size: clamp(12px, 1.3vw, 14px);
}

.admit-message {
    color: #CB07C3;

}

.circle {
    width: 12px;
    height: 12px;
    background: #CB07C3;
    border-radius: 50%;
}
</style>
