<template>
<!-- <div> -->
<!-- <div v-if="errorText" class="error" >*{{errorText}}</div> -->
<div class="label-input" :class="{'disabled-input ':disabled}">
    <label >{{label}}</label>
    <div class="input-container">
        <input  :maxLength="maxLength" :type="(type=='password'&& !passShow)?'password':'text'" @input="inputHandler()" v-model="temp" :disabled="disabled" :placeholder="placeholder" />
        <img  v-if="type=='password' && passShow" src="../../assets/icons/showpass.svg"  @click="passShow=!passShow"  class="input-icon"/>
        <img v-if="type=='password' && !passShow" src="../../assets/icons/hidepass.svg" @click="passShow=!passShow" class="input-icon" />
        <div class="unit" v-if="unit">{{unit}}</div>
    </div>
</div>

<!-- </div> -->
</template>

<script>
export default {
    name: 'LabelInput',

    props: ['label','unit', 'disabled', 'value', 'errorText', 'type', 'placeholder', 'maxLength'],

    data() {
        return {
            passShow: false,
            temp: this.value
        }
    },
    watch: {
        'value'() {
            this.temp = this.value
        }
    },
    methods: {
        inputHandler() {
            if (this.type == "number") {
                this.temp = this.$toLocal(this.temp)
            } else if (this.type == 'nochar')
                this.temp = this.temp.replace(/[^\d.]|\.\./ig, '')
            this.$emit('input', this.temp)
        },

    },
    mounted() {
        this.temp = this.value
    }
}
</script>

<style scoped>
.label-input {

    background: #060D35;
    border-radius: 4px;
    display: flex;
    max-height: 42px;
    flex-grow: 1;
    text-align: right;
    flex: 3;
}
.unit{
    color: #828282;
    font-size: clamp(9px,1.2vw,14px);
    padding: clamp(9px,1.7vw,12px);
}
.label-input:hover {
    /* background: #18204E; */
    /* box-shadow: -3px 3px 6px rgba(40, 42, 55, 0.2), 3px -3px 6px rgba(40, 42, 55, 0.2), -3px -3px 6px rgba(65, 65, 65, 0.9), 3px 3px 8px rgba(65, 65, 65, 0.9), inset 1px 1px 2px rgba(46, 50, 65, 0.3), inset -1px -1px 2px rgba(40, 42, 55, 0.5); */
}

input {
    height: 100%;
    flex-grow: 1;
    size: 1;
    width: 20px;
    background: transparent;
    color: white;

}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #BDBDBD;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #BDBDBD;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #BDBDBD;
}

.error {
    color: red;
    font-size: clamp(10px, 1.2vw, 14px);
    display: flex;
    line-height: 0px;
    align-items: center;
}

.input-container {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    position: relative;
}

label {
    border-radius: 0px 50px 50px 0px;
    font-size: clamp(12px, 1.3vw, 13px);
    color: #BDBDBD;
    display: flex;
    padding: 0px 10px 0px 10px;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;

}

.input-icon {
    margin: clamp(8px, 1vw, 14px);
    cursor: pointer;
    border: 1px solid;
    width: 19px;

}

.disabled-input {
    background: #13142A;
}
</style>
