<template>
<div class="label-input" :class="{'disabled-input ':disabled}">
    <div class="input-container">
        <input  id='calendar-input' />
        <div class="input-icon">
            <inline-svg class="calendar-icon" :src="require('../../assets/icons/calendar.svg')" />
        </div>
        <date-picker class="calender-comp" color='#143B74' v-model="date"
         custom-input="#calendar-input" :disabled="disabled" 
         range
        clearable
        auto-submit
         :type="'date'" :format="$dateFormat['enDate']" 
         :display-format="$dateFormat['enDate']" 
         
         >
         </date-picker>

    </div>

</div>
</template>

<script>
import DatePicker from 'vue-persian-datetime-picker'
export default {
    name: 'Calendar',
    components: {
        DatePicker
    },
    props: {
        label: String,
        type: String,
        value: String,
        disabled: Boolean,
        isBirth: Boolean
    },
    watch: {
        'date'() {
            if (this.date)
                this.$emit('input', this.date)
        },
        'value'() {
            this.date = this.value
        }
    },
    data() {
        return {
            passShow: false,
            date: this.value
        }
    },
}
</script>

<style scoped>
.label-input {
    /* width: 100%; */
    border-radius: 4px;
    display: flex;
    text-align: right;
    background: #18223D;
    flex-basis: 200px;
    flex-grow: 1;
}

input {
    height: 36px;
    flex-grow: 1;
    size: 1;
    width: 60px;
    color: #FCFCFC;
    background: transparent;

}

.input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}



.input-icon {
    display: flex;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
    padding: 0px 12px;
    height: 100%;

}

.calendar-icon {}

.disabled-input  {
    background: #13142A;
}

.calender-comp {
    opacity: 1;
    direction: ltr;
    color: black;
    position: absolute;
    height: auto;
    width: 100%;
}
</style>
