<template>
<div>

    <div class="history-table-title">
        {{lang.rialDepsoitHistory}}
    </div>
       <div class="filters">
        <RangeCalendar @input="value=>{startDate=value[0];endDate=value[1]}" />
    </div>

    <div class="history-table-container">
        <perfect-scrollbar @ps-y-reach-end="getdepsoitHistory()" ref="scroll">
            <table class="history-table">
                <tr class="history-table-header">
                    <td class="resp-1">{{lang.tableHeaders.time}}</td>
                    <td>{{lang.tableHeaders.volume}} <span class="tag" v-if="coin">({{$coins[coin].code}})</span></td>
                    <td class="resp-2" >{{lang.tableHeaders.cardNumber}}</td>
                    <td class="resp-1">{{lang.tableHeaders.trackNumber}}</td>
                    <td class="resp-2">{{lang.tableHeaders.status}}</td>
                </tr>
                <tr class="history-table-row" v-for="(transaction,index) in depsoitList" :key="index">
                    <td class="resp-1">{{$G2J(transaction.createdAtDateTime)}}</td>
                    <td dir='ltr' >{{$toLocal(transaction.amount,$decimal[transaction.relatedCoin])}}
                      </td>
                    <td class="resp-2">{{transaction.destinationWalletAddress || '-'}}</td>
                    <td class="resp-1">{{transaction.hashCode || '-'}}</td>
                    <td class="resp-2">
                        <div :style="{color:statusColor(transaction.transactionStatus)}"  class="status">{{lang.statusLabels[transaction.transactionStatus]}}</div>
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>

</div>
</template>

<script>
import RangeCalendar from '../../Tools/RangeCalendar'
import {
    statusLabel,
    statusColor

} from "@/library/reuseableFunction";

export default {
    name: 'Logins',
    components: {
        RangeCalendar
    },
    props: {

    },
  
    computed: {

      
        query() {
            let str = '?size=20&relatedCoin=TOMAN'
             if(this.startDate || this. endDate)
                str+='&startDate='+this.startDate+'T00:00:00'+'&endDate='+this.endDate+'T23:59:59'
       
            return str
        },

        statusLabel,
        statusColor
    },
    methods: {
        async getdepsoitHistory() {
            if (this.depsoitPage <= this.depsoitLastPage) { //for scroll loading
                this.depsoitPage++ //for scroll loading
                this.loading = true
                let params = {
                    page: this.depsoitPage,
                }

                const [res, ] = await this.$http.get(`wallets/deposit?${this.query}`, {
                    params
                })
                if (res) {
                    this.depsoitList = [...this.depsoitList, ...res.content]
                    this.depsoitLastPage = res.totalPages

                }
                this.loading = false

            }

        },

    },
    mounted() {
        this.getdepsoitHistory()
    },
    data() {
        return {
             endDate:'',
            startDate:'',
            lang: this.$languageDict().history,
            depsoitList: [],
            loading: false,
            depsoitPage: 0,
            depsoitLastPage: 1,
            // dict: {
            //     'depsoit': 'برداشت',
            // },
            coin: ''

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.left-filter {
    flex-grow: 1;
    max-width: 280px;
}

.mar {
    width: 600px;
}

@media(max-width:1000px) {

    .resp-1 {
        display: none;
    }
}

@media(max-width:600px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:400px) {
    .resp-3 {
        display: none;
    }
}
</style>
