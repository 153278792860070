<template>
<div class="guide-page">
  <GuideHeader/>
  <GuideContent/>
</div>
</template>

<script>
import GuideHeader from './GuideHeader'
import GuideContent from './GuideContent'
export default {
    name: 'Guide',
    components: {
        GuideHeader,
        GuideContent
    },
   
    data() {
        return {
            lang: this.$languageDict().guide,
          

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.guide-page{
  max-width: 100%;
  width: 100%;
  padding-bottom: 80px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

}
</style>
