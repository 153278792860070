<template>
<div class="public-page">
    <div class="page-header">Terms of Use</div>
    <!-- <div class="passage-container" v-html="privacyPolicy" /> -->
<p>
    The following terms shall be understood as follows:

Regulations – these Regulations, specifying, in particular, the principles of concluding remote contracts with or through Website as well as the principles of performance of these contracts, the rights and obligations of the Service Provider and the User, and the principles of complaint procedure;
Service Provider – CDSV LLC – a company incorporated in accordance with the laws of St. Vincent and the Grenadines, bearing registration number 753 LLC 2020 with registered seat at Suite 305, Griffith Corporate Centre, Beachmont, Kingstown, St. Vincent and the Grenadines;
Cryptocurrency – a transferable property right which is neither legal tender, electronic money nor a financial instrument. The list of Cryptocurrencies whose turnover, deposits and withdrawals are allowed on the Website is published on the Website. The change of the list mentioned above shall not constitute a change of the Service Contract;
User – an entity which uses the Website;
Consumer – a natural person using the Website in a manner not directly related to its business or professional activity;
Service – providing Users with an electronic platform for the exchange of information and concluding Transactions, as well as other actions of the Service Provider, enabling the operation and use of the Website;
Service Contract – the contract concluded between the Service Provider and the User, by acceptance of the Regulations by the User, which defines the principles of using the Services provided by the Service Provider by the User, the content of the Service Contract include: Regulations, table of fees and pricing posted on the Website in tab “fees”, KYC / CDD policy, privacy policy; 
Website – the Service Provider’s webpage operating under the domain Coindeal.com with all its subpages and tabs;
</p>
</div>
</template>

<script>
export default {
    name: 'Terms',
    methods: {
        // async getInfo() {
        //     const res = await this.$axios.get('/exchange-info')
        //     console.log(res)
        //     this.privacyPolicy = res.baseDTO.privacyPolicy || ''
        // },
    },
    data() {
        return {
            privacyPolicy: ''
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style scoped>
p{
    color: white    ;
}
.page-header {
    color: #FFFFFF;
    font-size: clamp(22px, 2.8vw, 36px);
    font-weight: bold;

}
.public-page{
    background: radial-gradient(42.49% 63.74% at 50% 50%, #2A1A8F 0%, #020E38 100%);
    width: 85%;

}
.passage-container {
    color: #FFFFFF;
    border-radius: 6px;
    padding: 24px;

}
</style>
