<template>
<div class="trade-details">
    <div class="detail-sec">
        <div class="head">
            {{language.secTitle}}
        </div>
        <div class="details">
            <div class="detail">
                <div class="value">{{valueUnitBuilder(userscount)}}</div>
                <div class="label">{{language.usersLabel}}</div>
            </div>
            <div class="detail">
                <div class="value">{{valueUnitBuilder(tradesCount)}}</div>
                <div class="label">{{language.transactionsLabel}}</div>
            </div>
            <div class="detail">
                <div class="value">{{valueUnitBuilder(tradesVolume)}}</div>
                <div class="label">{{language.tadeVolumeLabel}}</div>
            </div>

        </div>
    </div>
    <div class="img-container">
         <img src='../../../assets/images/tradeDetailsImg.svg'/>

    </div>

</div>
</template>

<script>
export default {
    name: 'TradeDetails',
   
    data() {
        return {
            language: this.$languageDict().homepage.tradeDetails,
            userscount: '0',
            tradesVolume: '0',
            tradesCount: '0'

        }
    },

    methods: {
        async getInfo() {
            const res = await this.$axios('trades/market-info-home')
            this.userscount = res.baseDTO.userCount
            this.tradesVolume = res.baseDTO.tradesValue
            this.tradesCount = res.baseDTO.tradesAmount
        },
        valueUnitBuilder(value) {
            let unit = ''
            if (value > 1000000) {
                unit = 'M+'
                value = this.$toLocal(value / 1000000, 0)
            } else if (value > 1000) {
                unit = 'K+'
                value = this.$toLocal(value / 1000, 0)
            }
            return value + unit

        }

    },
    mounted() {
        this.getInfo()
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.trade-details {
    margin-top: clamp(80px,11vw,154px);
    color: #ffffff;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 40px;
    display: flex;
    justify-content: space-between;

}

.detail-sec {
    row-gap: 30px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    max-height: 260px;
    flex-grow: 3;
}
.img-container{
    display: flex;
    flex-grow: 1;
    max-width: 386px;
}
.img-container > img{
    width: 100%;
}
.details {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
}

.head {
    font-size: clamp(24px, 2.8vw, 36px);
    max-width: 490px;

}

.label {
    font-size: clamp(18px, 2vw, 24px);
}

.value {
    font-size: clamp(30px, 3.2vw, 40px);
    color: #CB07C3;
}
</style>
