<template>
<div class="navbar ">
    <div class="links ">
        <a v-for="(link,index) in links" class="resp" :key="index" @click.prevent="link=='Guide'?$router.push('/'+link):$router.push('/Panel/'+link)" :href="'/Panel/'+link">
            {{lang.links[link]}}
        </a>
    </div>
    <div class="profile-group">
        <img src="../../assets/icons/notifications.svg"  @click="$router.push('/Panel/Notifications')"/>
        <!-- <div class="lang-container " @click="handleLang()">
            <img :src="require('../../assets/icons/'+state.lang+'-flag.svg')" />
        </div> -->
        <div class="user-profile">
            <div class="username-container">
                <div class="user-brief-name">
                    {{state.userInfo.firstName[0]+state.userInfo.lastName[0]}}
                </div>
                <div class="user-fullname">
                    {{state.userInfo.firstName+' '+state.userInfo.lastName}}
                </div>

            </div>
            <inline-svg :class="{'open-icon':dropOpen}" :src="require('../../assets/icons/arrowdown.svg')" class="arrow-icon"  @click="dropOpen=!dropOpen"/>
            <div
             class="dropdown" :style="{height:dropOpen?30*(profileLinks.length+1)+'px':'0px',opacity:1}">
                 <a v-for="(link,index) in profileLinks" :href="'/Panel'+link.path" 
                 @click.prevent="$router.push('/Panel'+link.path);dropOpen=false" :key="index" class="access">
                     {{lang.profileLinks[link.name]}}
                 </a>
                 <div class="access" @click="showExitModal=true">{{lang.logout}}</div>

            </div>
        </div>
    </div>
<ExitModal v-if="showExitModal" @modalToggle="showExitModal=false;dropOpen=false"/>
</div>
</template>

<script>
import ExitModal from '../Modals/ExitModal'
export default {
    name: 'PanelNavbar',
    props: {},
    components:{
        ExitModal
    },
    data() {
        return {
            lang:this.$languageDict().panelNavbar,
            dropOpen:false,
            showExitModal:false,
            links: [
                "Trade",
                "Exchange",
                "Guide",
            ],
            profileLinks:[
                {
                    name: "Dashboard",
                    path:"/Dashboard"
                },
                {
                    name: "Profile",
                    path:"/Profile"
                },
                {
                    name: "Wallets",
                    path:"/Wallets"
                },
                {
                    name:"Trade",
                    path:"/Trade/TOMAN/BITCOIN"
                },

            ]
        }
    },
    // computed: {
    //     getLang() {
    //         return JSON.parse(localStorage.getItem('lang'))

    //     }
    // },
    // watch:{
    //     "getLang":function(){
    //         console.log('dsfsadf')
    //     }
    // },
    methods: {
        handleLang() {
            this.state.lang=(this.state.lang == 'en')?'fa':'en'
            localStorage.setItem('lang',this.state.lang) 

        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.open-icon{
    transform: rotate(-180deg);
}
.dropdown{
    position:absolute;
    width: 100%;
    background: #162045;
    top: 100%;
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    transition:height 0.5s;
    height: 0px;
    opacity: 0;
    overflow: hidden;


    .access{
        color: #fcfcfc;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: clamp(13px,1.4vw,16px);
        height: 30px;
        min-height: 30px;
    }
    .access:hover{
        color: #EF07A0;
    }


}
.navbar {
    position: sticky;
    top: 0;
    padding: 17px clamp(10px, 1.5vw, 17px) 10px clamp(10px, 1.5vw, 17px);
    // background: transparent;
    width: 100%;
    display: flex;
    column-gap: 25px;
    z-index: 60;
    align-items: center;
    justify-content: space-between;
    background: rgba(2, 14, 56, 1);

}

.arrow-icon {
    fill: #fcfcfc;
    cursor: pointer;
    width: 14px;
}

.links {
    max-width: 304px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    justify-content: space-between;
    flex-grow: 1;
    font-size: clamp(15px, 1.6vw, 18px);
}
.links > a{
    color: #BDBDBD;

}

.lang-container {
    display: flex;
    align-items: center;
    padding: 0px 4px;
    background: #162045;
    border-radius: 4px;
    cursor: pointer;
}

.username-container {
    display: flex;
    column-gap: 6px;
    height: 100%;
    align-items: center;

}

.profile-group {
    min-height: 40px;
    display: flex;
    column-gap: 8px;
    direction: ltr!important;

    // max-width: 283px;
    // flex-grow: 1;

}

.user-profile {
    display: flex;
    align-items: center;
    background: #162045;
    border-radius: 4px;
    flex-grow: 1;
    justify-content: space-between;
    column-gap: 10px;
    padding-right: 7px;
    position: relative;
    direction: ltr!important;

}

.user-fullname {
    color: #FCFCFC;
}

.user-brief-name {
    background: linear-gradient(225deg, #18D5FF -14.29%, #6E12B6 52.07%, #FF00A8 112.52%);
    border-radius: 4px 0px 0px 4px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    height: 100%;
    font-size: clamp(15px, 1.6vw, 18px);
}
@media(max-width:720px){
    .resp{
        display: none;
    }
   
}
</style>
