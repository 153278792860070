<template>
<ModalCard @close="$emit('modalToggle','')">
    <template #title>
       {{lang.modalTitle}}
    </template>
    <template #body>
        <div class="modal-text"> {{lang.topText}}</div>
        <LabelInput @input='value=>amount=value' type="number" :label='lang.amountLabel' />
        <div class="card-select">
            <label class="modal-text">{{lang.numberLabel}}</label>
            <DropDown v-if="banksList" @choose="value=>walletAddress=value" :items="banksList" />
        </div>
        <button class="modal-submit-btn" :class="{disabled:!amount || !walletAddress || ($S2N(amount) < 1000)}" 
        :disabled="!amount || !walletAddress || ($S2N(amount) < 1000)" @click="deposit">انتقال به درگاه پرداخت </button>
    </template>

</ModalCard>
</template>

<script>
import ModalCard from './ModalCard'
import DropDown from '../Tools/DropDown'
export default {
    name: 'RialDepsoit',
    components: {
        ModalCard,
        DropDown
    },
    data() {
        return {
            lang: this.$languageDict().wallets.modals.rialDepsoit,
            banks: [],
            walletAddress: '',
            amount:'',
            regex: '',
            show: false

        }
    },
    computed: {
        banksList() {
            if (this.banks)
                return this.banks.map(bank => {
                    return {
                        label: bank.cardNumber,
                        value: bank.cardNumber
                    }
                })
            return false
        },
    },
    methods: {
        chooseCard(cardNumber) {
            this.walletAddress = cardNumber
            this.regex = ''
            this.show = false
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.banks = res.content
            this.walletAddress=this.banks[0].cardNumber
        },
        async deposit() {
            this.state.loading = true
            let walletInfo = {
                amount: this.$S2N(this.amount),
                paymentGateway: 'ZARRINPAL',
                cardNumber: this.walletAddress,
                callBackURL: this.$callBackURL
            }
            const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)
            if (res.message === "Deposit Request Created Successfully") {
                this.$emit('modalToggle', '');
                window.open(res.baseDTO.redirectSite, "_self")
            }
        },

    },
    async mounted() {
        await this.getData()
    }
}
</script>

<style scoped>
.text {
    font-size: clamp(12px, 1.3vw, 14px);
    color: var(--modal-text)
}
.card-select{
    display: flex;
    column-gap: 6px;
    align-items: center;
}
.disabled {
    opacity: 0.6;
}
</style>
