<template>
<div class="settings">
    <div class="twoFA-changePass">
        <TwoFA />
        <ChangePass />
    </div>
    <Logins />

</div>
</template>

<script>
import Logins from './Logins'
import TwoFA from './TwoFA'
import ChangePass from './ChangePass'
export default {
    name: 'Settings',
    components: {
        ChangePass,
        TwoFA,
        Logins
    },
    props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
::v-deep .text {
    color: #FFFFFF;
    font-size: clamp(14px, 1.6vw, 18px);
    margin-top: 12px;
}

::v-deep .section-title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: clamp(16px, 2vw, 24px);
}

::v-deep .section {
    background: linear-gradient(169.98deg, rgba(111, 111, 116, 0.34) 0%, rgba(67, 70, 141, 0.32) 52.32%, rgba(60, 60, 68, 0.32) 92.49%);
    border-radius: 8px;
    padding: clamp(14px, 2vw, 24px);
    flex-grow: 1;
}

.twoFA-changePass {
    display: flex;
    column-gap: 24px;
    flex-wrap: wrap;
    row-gap: 24px;
}
.settings{
    padding-bottom: 90px;
}
</style>
