<template>
<div class="section">
    <div class="section-title">{{lang.sectionTitle}}</div>
    <div class="section-text">{{lang.sectionText}}</div>
    <div class="main-content">
        <div class="statement-section">
            <div class="part-text">{{lang.statementLabel}}</div>
            <div class="part-text ">{{lang.statementText}}</div>
        </div>
        <div>
            <div class="part-text">{{lang.sampleLabel}}</div>
            <div class="upload-samples">
                <img src="../../../assets/images/upload-sample-wrong.png" />
                <img src="../../../assets/images/upload-sample-right.png" />
            </div>
        </div>
        <div>
            <div class="part-text bold">{{lang.uploadAreaLabel}}</div>
            <div class="upload-section">
                <input   @change="upload($event.target)" type="file"  accept="image/*" name="image" id="file" />
                <img v-if="path" :src="path" id='user-img'/>
                <div v-else class="upload-suggest">
                    <img src='../../../assets/icons/upload-icon.svg'/>
                    <div>{{lang.uploadText}}</div>
                </div>
                <button class="upload-btn" @click.prevent="">
                    <div class="btn-bg">
                        <span class="gradiant-font">{{lang.uploadBtn}}</span>
                    </div>
                </button>

            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'ImageUpload',
     props:{
          data:Object
  },
    data() {
        return {
            lang: this.$languageDict().profile.imageUpload,
              path: '',
                loadedpicture: '',
                hovered: '',
        }
    },
     methods:{
             drop(e) {
                let dt = e.dataTransfer
                this.upload(dt)
            },
            upload(evt) {
                let file = evt.files[0]
                this.data.image = file
                let fileReader = new FileReader();
                fileReader.addEventListener("loadstart", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                })
                fileReader.addEventListener("progress", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                });
                fileReader.addEventListener('load', (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                    this.path = event.target.result
                });
                fileReader.readAsDataURL(file);
                // this.loadFile(evt)
            },
            // loadFile(event){         
            //         var image = document.getElementById('output-img');
            //         this.data.image=URL.createObjectURL(event.files[0]);
            //         image.src = URL.createObjectURL(event.files[0]);
            //         document.getElementById('upload-image-sub').style.display="none";
            //         console.log(this.data.image);
            // }
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#user-img{
    height: 150px;
}
.main-content {
    display: flex;
    column-gap: 10px;
    row-gap: 18px;
    margin-top: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.upload-samples {
    column-gap: 16px;
    row-gap: 16px;
    display: flex;
    /* flex-wrap: wrap; */
}
.upload-samples > img{
    width: 100%;
    min-width: 10px;
}

.main-content>div {
    flex-grow: 1;
    flex-basis: 250px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.part-text {
    font-size: clamp(15px, 1.6vw, 18px);
    color: #FFFFFF;
}
input[type="file"]{
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: 3;
    height: 100%;
    cursor: pointer;
    border: 1px blue;
}
.bold {
    font-weight: bold;
}

.statement-section {
    max-width: 372px;
}

.upload-section {
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
    align-items: center;
    padding-top: 24px;
    background: #2A2747;
    box-shadow: inset 4px 4px 4px rgba(13, 12, 44, 0.73), inset -4px -4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    flex: 1;
}

.upload-btn {
    border-radius: 8px;
    font-size: clamp(14px, 1.5vw, 16px);
    font-weight: bold;
    padding: 2px;
    background: linear-gradient(224.33deg, #6ADBFF 7.52%, #A781EE 46.64%, #FF00B8 102.53%);
    max-height: 35px;
    height: 35px;
    display: flex;
    width: 100%;
}

.upload-suggest{
    font-size: clamp(15px, 1.6vw, 18px);
    color: #E0E0E0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.btn-bg {
    background: #2A2747;
    border-radius: 8px;
    padding: 0px 12px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.gradiant-font {
    background: linear-gradient(224.33deg, #6ADBFF 7.52%, #A781EE 46.64%, #FF00B8 102.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
}
</style>
