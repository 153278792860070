<template>
<div class="auth-page">
    <div class="auth-card">
        <div class="auth-card-title">{{lang.title}}</div>
        <form class="auth-form" @submit.prevent="send()">
            <p class="auth-card-text" v-if="pathMatch === 'email'">{{lang.emailText}}</p>
            <p class="auth-card-text" v-else-if="pathMatch === 'google-auth'">{{lang.googleText}}</p>
            <p class="auth-card-text" v-else-if="pathMatch === 'sms'">{{lang.phoneText}}</p>
            <span v-if="pathMatch !== 'google-auth'" class="countdown">{{Math.floor(countDown/60)}}:{{countDown%60}}</span>
            <VerifyCodeInput @change="e=>otp.code=e" :num="6" />
            <p class="auth-card-text">{{lang.emailForText}} {{otp.email}}</p>
            <LabelInput @input="value=>{otp.password=value;}" type='password' :label="lang.passLabel" />
            <LabelInput @input="value=>{passConfirm=value;}" type='password' :label="lang.passConfirmLabel" />
            <button  v-if="countDown>0"   :class="{disabled:!otp.code ||!otp.password  }" :disabled="!otp.code ||!otp.password "
                 type="submit" class="auth-confirm-btn">{{lang.confirmBtn}}</button>
            <button v-if="countDown===0" @click.prevent="send()"  class="auth-confirm-btn">{{lang.confirmBtn}}</button>
        </form>

    </div>

</div>
</template>

<script>
export default {
    name: 'ChangePass',
    data() {
        return {
            lang: this.$languageDict().changePass,
            pathMatch: this.$route.params.type,
            passError: '',
            countDown: 120,
            passConfirm:'',
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username') || '',
                password: ''
            }
        }
    },

    methods: {
        showPass() {
            if (this.type === "password") {
                this.type = 'text'
            } else {
                this.type = 'password'
            }
        },
        checkPass() {
            if (!this.otp.password) {
                this.passError = 'رمز اجباری';
            } else {
                if (this.otp.password.length < 8) {
                    this.passError = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.otp.password)) {
                    this.passError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                }else if (this.passConfirm!==this.otp.password) {
                    this.passError = 'رمز و تکرار آن مطابقت ندارند';
                } else {
                    this.passError = ''
                }
            }
            if (!this.passError.length) {
                return true;
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        validPass(password) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        },
        async submit() {
            console.log('check1')
            if (this.checkPass(this.otp.password) && !this.disabled) {
                this.state.loading = true
                let [res, ] = await this.$http.post('/users/reset-password', this.otp)
                // this.reset()
                console.log('check2')

                if (res) {
                    console.log('check3')

                    this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                    await this.$router.push('/login')
                }
            } else if (this.passError) {
                this.$error(this.passError, '', 'error')

            }
        },
        async send() {
            this.state.loading = true
            // this.reset()
            await this.$axios.post('/users/forget-password?email=' + this.otp.email)
            this.$error('کد جدید ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)

            if (this.countDown === 0) {
                if (this.state.userInfo.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR')
                    this.countDown = 120
                this.countDownTimer()
            }
        },
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        
        // this.setPlaceholder()
        if (!this.$cookies.isKey('username')) {
            this.$router.push('/login')
        }
        if (this.pathMatch !== 'google-auth') {
            this.countDownTimer()
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
