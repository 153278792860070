<template>
<div class="section">
    <div class="section-title">{{lang.sectionTitle}}</div>
    <div class="section-text">{{lang.sectionText}}</div>
    <div class="new-card-box">
        <div class="box-title">{{lang.newAccount}}</div>
        <div class="new-card-inputs">
            <LabelInput type="nochar" :value="creditCard.cardNumber" :maxLength="16" @input="(value)=>cardNumberHandle(value)" :label='lang.cardNumber' />
            <LabelInput type="nochar" :value="creditCard.shebaCode" :maxLength="24" @input="(value)=>creditCard.shebaCode=value" :label='lang.iban' />
            <LabelInput class="provider-input" :value="creditCard.bankName" :disabled="true" :label='lang.provider' />
            <button class="add-btn" @click.prevent="">
                <div class="btn-bg">
                    <span class="gradiant-font">{{lang.addBtn}}</span>
                </div>
            </button>
        </div>
    </div>
    <div class="cards-box">
        <div class="box-title">{{lang.accounts}}</div>
        <div class="cards-list">
            <div class="old-card-input" v-for="(bankAccount,index) in bankAccounts" :key="index">
                <LabelInput type="nochar" :value="bankAccount.cardNumber" :label='lang.cardNumber' :disabled="true" />
                <LabelInput class="resp-1" type="nochar" :value="bankAccount.shebaCode" :label='lang.iban' :disabled="true" />
                <LabelInput class="provider-input resp-2" :value="bankAccount.bankName" :disabled="true" :label='lang.provider' />
                <div class="trash-border" @click="removeAccount(index)">
                    <img src='../../../assets/icons/trash.svg' />
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'BankInformation',
    props: ['bankAccounts'],

    data() {
        return {
            lang: this.$languageDict().profile.bankInformation,
            creditCard: {
                bankName: "",
                shebaCode: "",
                cardNumber: "",
                owner: ""
            },
            banks: {
                '603799': 'بانک ملی ایران',
                '589210': 'بانک سپه',
                '627648': 'بانک توسعه صادرات',
                '207177': 'بانک توسعه صادرات',
                '627353': 'بانک تجارت',
                '585983': 'بانک تجارت',
                '627961': 'بانک صنعت و معدن',
                '603770': 'بانک کشاورزی',
                '639217': 'بانک کشاورزی',
                '628023': 'بانک مسکن',
                '627760': 'پست بانک ایران',
                '502908': 'بانک توسعه تعاون',
                '627412': 'بانک اقتصاد نوین',
                '622106': 'بانک پارسیان',
                '639194': 'بانک پارسیان',
                '627884': 'بانک پارسیان',
                '502229': 'بانک پاسارگاد',
                '639347': 'بانک پاسارگاد',
                '639599': 'بانک قوامین',
                '504706': 'بانک شهر',
                '502806': 'بانک شهر',
                '603769': 'بانک صادرات',
                '610433': 'بانک ملت',
                '991975': 'بانک ملت',
                '589463': 'بانک رفاه',
                '502938': 'بانک دی',
                '639607': 'بانک سرمایه',
                '627381': 'بانک انصار',
                '505785': 'بانک ایران زمین',
                '636214': 'بانک آینده',
                '636949': 'بانک حکمت ایرانیان',
                '621986': 'بانک سامان',
                '639346': 'بانک سینا',
                '606373': 'بانک قرض الحسنه مهر',
                '627488': 'بانک کارآفرین',
                '502910': 'بانک کارآفرین',
                '505416': 'بانک گردشگری',
                '639370': 'بانک مهر اقتصاد',
                '628157': 'موسسه اعتباری توسعه',
                '505801': 'موسسه اعتباری کوثر',
            },
        }
    },
    methods: {

        cardNumberHandle(value) {
            let temp = Object.entries(this.banks).filter(bank => {
                if (value.startsWith(bank[0]) || bank[0].startsWith(value) && value)
                    return bank[0]
            })[0]
            console.log(temp)
            this.creditCard.bankName = temp ? this.banks[temp[0]] : 'کارت نامعتبر'
            this.creditCard.cardNumber = value
            console.log(this.creditCard.cardNumber.length)
            console.log(this.creditCard.shebaCode.length)

        },
        removeAccount(index) {
            if (this.bankAccounts.length > 1)
                this.bankAccounts.splice(index, 1)
        },
        addAccount() {
            this.bankAccounts.push(this.creditCard);
            this.creditCard = {
                bankName: "",
                shebaCode: "",
                cardNumber: ""
            }
        },
        dash(e) {
            this.bankAccounts[e].bankName = this.banks[this.bankAccounts[e].cardNumber ? this.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
            this.bankAccounts[e].cardNumber = this.$separator(this.bankAccounts[e].cardNumber)
            this.error = ''
        }
    },
    // mounted() {
    //     for (let [index] of this.bankAccounts.entries()) {
    //         this.dash(index)
    //     }
    // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>






.new-card-box {
    background: #18223D;
    border-radius: 4px;
    padding: 12px 8px 12px 16px;
    margin-top: clamp(18px, 2vw, 24px);
}

.trash-border {
    border: 2px solid #82112B;
    border-radius: 4px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cards-box {
    background: #222A41;
    border-radius: 4px;
    padding: 12px 8px 12px 16px;
    margin-top: clamp(18px, 2vw, 24px)
}

.box-title {
    color: #CECECE;
    font-size: clamp(15px, 1.6vw, 18px);
    font-size: bold;
}

.new-card-inputs {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    margin-top: 12px;
    flex-wrap: wrap;
}

.old-card-input {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    margin-top: 12px;
}

.new-card-inputs>div {
    flex-basis: 210px;
}

.add-btn {
    flex-grow: 2;
    border-radius: 4px;
    font-size: clamp(14px, 1.5vw, 16px);
    padding: 1px;
    background: linear-gradient(224.33deg, #6ADBFF 7.52%, #A781EE 46.64%, #FF00B8 102.53%);
    /* max-width: 187px; */
    height: 42px;
}

.provider-input {
    max-width: 240px;
    flex-grow: 2 !important;
}

.btn-bg {
    background: #1a597a;
    border-radius: 4px;
    padding: 0px 12px;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.gradiant-font {
    background: linear-gradient(224.33deg, #6ADBFF 7.52%, #A781EE 46.64%, #FF00B8 102.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

@media(max-width:1065px) {

    .resp-1 {
        display: none !important;
    }
}

@media(max-width:640px) {

    .resp-2 {
        display: none !important;
    }
}

</style>
