<template>
<div class="p2p">
    <div class="upper-section">
        <TradeComp :minAmount="crypto.minAmount" :crypto="crypto" :maxAmount="crypto.maxAmount" :shopInfo="shopInfo"  @changeBaseCoin="value=>baseCoin=value" />

        <div class="headbar-and-tradingview">
            <div class="head-bar">
                <div class="label-value">
                    <div class="label">{{lang.change}}</div>
                    <div class="value change" dir="ltr"><span v-if="crypto.last24HourChange>0">+</span>
                        <span v-if="crypto.last24HourChange<0">-</span>{{$toLocal(Math.abs(crypto.last24HourChange),2)}}%</div>
                </div>
                <div class="label-value">
                    <div class="label">{{lang.high}} </div>
                    <div class="value">{{$toLocal(crypto.maxPrice,$decimal[tradeTo])}} </div>
                </div>
                <div class="label-value">
                    <div class="label">{{lang.low}} </div>
                    <div class="value">{{$toLocal(crypto.minPrice,$decimal[tradeTo])}} </div>
                </div>
                <div class="label-value">
                    <div class="label">{{lang.volume}} </div>
                    <div class="value">{{$toLocal(crypto.last24HourVolume,$decimal[tradeTo]) }} </div>
                </div>
            </div>
            <TradingView :key="tradeFrom" :cryptoSymbol="$coins[tradeFrom].code" />

        </div>
        <CoinsList :cryptos="cryptos" />

    </div>
    <div class="lower-section">
        <OrderBook :tableContentsBuy="tableContentsBuy" :tableContentsSell="tableContentsSell" />
        <OrdersTable />
    </div>
</div>
</template>

<script>
import OrdersTable from './OrdersTable'
import TradeComp from './TradeComp'
import TradingView from './TradingView'
import OrderBook from './OrderBook'
import CoinsList from './CoinsList'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'P2P',
    components: {
        TradingView,
        TradeComp,
        OrdersTable,
        CoinsList,
        OrderBook
    },
    data() {
        return {
            lang: this.$languageDict().trade.headeBar,

            tableContentsSell: [],
            tableContentsBuy: [],
            coinsDetails: [],
            shopInfo: {
                minSell: 0,
                maxBuy: 0
            },

            baseCoin: 'TETHER',
            crypto: {},
            baseCoinDict: {
                'TETHER': 'تتر',
                'TOMAN': 'تومان'
            },
            cryptos: []
        }
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    watch: {
        'tradeTo': function () {
            this.getCoins()
        }
    },
    async mounted() {
        await this.getCoinsDetails()
        this.loading = true
        if (this.state.loop) {
            this.loop1 = new Loop(this.getCoins, 5000, 'realPrice' + (this.tradeFrom))
            this.loop2 = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '_' + this.tradeTo)

            this.loop1.start()
            this.loop2.start()
        } else {
            this.getCoins()
            this.getMarketDepth()

        }

    },
    beforeDestroy() {
        this.loop1.stop()
        this.loop2.stop()
    },
    methods: {

        async getCoins() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res.filter(crypto => crypto.marketType.includes(this.tradeTo))
                this.setCrypto()
            }
        },
        async getCoinsDetails() {
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content

        },
        async getMarketDepth() {

            const [res, ] = await this.$http.get('/orders/order-book', {
                params: {
                    marketType: this.tradeFrom + '_' + this.tradeTo
                }
            })
            if (res) {
                this.tableContentsSell = res.sellOrderBook.slice(0, 11).sort((a, b) => {
                    a.unitPrice > b.unitPrice
                })
                this.tableContentsBuy = res.buyOrderBook.slice(0, 11).sort((a, b) => {
                    return a.unitPrice > b.unitPrice
                })
                this.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                this.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
            }
            this.loading = false
        },

        setCrypto() {
            if (this.cryptos) {
                console.log('dfssdf')
                this.crypto = this.cryptos.find(crypto => crypto.marketType.includes(this.tradeFrom))
                if (this.crypto && this.coinsDetails) {
                    this.crypto.minAmount = this.coinsDetails.find(coin => coin.coin == this.tradeFrom).minAmount
                    this.crypto.maxAmount = this.coinsDetails.find(coin => coin.coin == this.tradeFrom).maxAmount
                }

            }

        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.p2p{
    padding-bottom: 40px;
}
.upper-section {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;

    
}
.upper-section > div{
    flex-grow: 1;
}
.headbar-and-tradingview{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.lower-section{
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
    margin-top: 12px;
}

.head-bar{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 12px;
    padding: 0px clamp(18px,2.3vw,30px);
    row-gap: 14px;
    

    .label-value{
        color: #FFFFFF;
        text-align: center;
        .label{
            font-size: clamp(12px,1.3vw,14px);
        } 

        .value{
            margin-top: 8px;
            font-size: clamp(14px,1.6vw,18px);
        }
    }
    
}
.green {
    color: #80B73B;
}

.red {
    color: #DD1B60;
}
</style>
